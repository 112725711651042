import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Avatar, Grid, Theme, Typography } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logoutUser, selectUser } from './authenticationSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      border: '1px solid white',
      marginLeft: '10px',
      marginRight: '15px',
    },
    itemGrid: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    itemText: {
      fontSize: 24,
      fontWeight: 500,
      color: 'white',
      opacity: 0.85,
    },
  })
);

const SignOut = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const handleLogoutUser = async () => {
    if (user) {
      await dispatch(logoutUser(user.email));
    }
    // handleCloseDialog();
  };

  return (
    <Fragment>
      <Grid className={classes.itemGrid} container justifyContent="space-between">
        <Typography className={classes.itemText}>{user?.userName}</Typography>
        <Avatar
          className={classes.iconButton}
          component={Link}
          to="/"
          onClick={handleLogoutUser}
          alt={user?.userName}
          src={user?.iconUri}
        />
      </Grid>
    </Fragment>
  );
};

export default SignOut;
