import { Terrain } from 'interfaces/terrain.interface';
import { get } from './request.axios';

const apiPath = `/terrain`;
const apiSubPath = `/course`;

export const getTerrain = async (terrainId: string) => {
  return get<{ data: Terrain }>(`${apiPath}/${terrainId}`).then(response => {
    return response.data;
  });
};

export const getTerrains = async () => {
  return get<{ data: Terrain[] }>(`${apiPath}`).then(response => {
    return response.data;
  });
};

export const getTerrainVersion = async (courseId: string, terrainVersion: string | number) => {
  return get<{ data: Terrain }>(`${apiSubPath}/${courseId}/terrain/${terrainVersion}`).then(response => {
    return response.data;
  });
};

export const getTerrainVersions = async (courseId: string) => {
  return get<{ data: Terrain[] }>(`${apiSubPath}/${courseId}/terrain`).then(response => {
    return response.data;
  });
};
