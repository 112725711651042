import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { RootState } from 'app/store';
import { User } from 'interfaces/user.interface';

import { login, logout, register, status } from 'api/axios/auth.axios';

export interface AuthState {
  user: User | null;
  status: boolean;
  error: string;
}

const initialState: AuthState = {
  user: null,
  status: false,
  error: '',
};

export const getStatus = createAsyncThunk('authentication/status', async (_, thunkAPI) => {
  try {
    const response = await status();
    return response.status === 200;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
    return thunkAPI.rejectWithValue('Failed to complete request');
  }
});

// how do we catch thunk errors?
export const registerUser = createAsyncThunk(
  'authentication/register',
  async ({ email, userName, userPassword }: { email: string; userName: string; userPassword: string }, thunkAPI) => {
    try {
      const response = await register(email, userName, userPassword);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue('Failed to complete request');
    }
  }
);

export const loginUser = createAsyncThunk(
  'authentication/login',
  async ({ email, userPassword }: { email: string; userPassword: string }, thunkAPI) => {
    try {
      const response = await login(email, userPassword);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue('Failed to complete request');
    }
  }
);

export const logoutUser = createAsyncThunk('authentication/logout', async (email: string, thunkAPI) => {
  try {
    const response = await logout(email);
    return response.status === 200;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
    return thunkAPI.rejectWithValue('Failed to complete request');
  }
});

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.status = action.payload;
      state.error = '';
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.user = null;
      state.status = false;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.error = '';
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      if (action.error.message) {
        state.error = (action.payload as AxiosError).message;
      }
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = true;
      state.error = '';
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      if (action.error.message) {
        state.error = (action.payload as AxiosError).message;
      }
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.user = null;
      state.status = false;
      state.error = '';
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.user = null;
      state.status = false;
      state.error = '';

      if (action.error.message) {
        state.error = (action.payload as AxiosError).message;
      }
    });
  },
});

export const { setStatus } = authenticationSlice.actions;

export const selectStatus = (state: RootState) => state.authentication.status;
export const selectUser = (state: RootState) => state.authentication.user;

export default authenticationSlice.reducer;
