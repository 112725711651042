import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface XrModeState {
  enable: boolean;
}

const initialState: XrModeState = {
  enable: false,
};

const xrModeSlice = createSlice({
  name: 'view/xrMode',
  initialState,
  reducers: {
    enableXrMode: (state, action: PayloadAction<boolean>) => {
      state.enable = action.payload;
    },
  },
  extraReducers: {},
});

export const { enableXrMode } = xrModeSlice.actions;

export const selectXrMode = (state: RootState) => state.xrMode.enable;

export default xrModeSlice.reducer;
