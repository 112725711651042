import { Drawer, makeStyles } from '@material-ui/core';
import { useInjection, useObservable } from 'app/hooks';
import StateProducer, { StateConsumer } from 'features/state';
import { useEffect, useState } from 'react';
import PlayBackService from 'services/playBack.service';
import RacePathService from 'services/racePath.service';
import SkiGatesService from 'services/skiGates.service';

const colors = {
  string: '#CE9178',
  number: '#B5CEA8',
  text: '#D4D4D4',
  boolean: '#569CD6',
  type: '#4EC9B0',
  background: '#1E1E1E',
};

const useStyles = makeStyles(theme => ({
  string: {
    color: colors.string,
  },
  number: {
    color: colors.number,
  },
  object: {
    color: colors.text,
  },
  type: {
    color: colors.type,
  },
  boolean: {
    color: colors.boolean,
  },
  symbol: {
    color: colors.boolean,
  },
}));

const Identation = (props: { level: number }) => (
  <span style={{ display: 'inline-block', width: `${props.level * 20}px` }} />
);

type PrimitiveValue =
  | {
      type: 'number';
      value: number;
    }
  | {
      type: 'string';
      value: string;
    }
  | {
      type: 'boolean';
      value: boolean;
    }
  | {
      type: 'symbol';
      value: null | undefined | symbol;
    };

const PrimitiveValueElement = ({ value }: { value: PrimitiveValue }) => {
  const classes = useStyles();
  return <span className={classes[value.type]}>{JSON.stringify(value.value)}</span>;
};

type ObservableValue = {
  type: 'observable';
  value$: StateConsumer<any>;
};

type EagerValue = ObjectValue | PrimitiveValue;
type Value = EagerValue | ObservableValue;

type ObjectValue = {
  type: 'object';
  typeName?: string;
  children: Array<{
    name: string;
    value: Value;
  }>;
};

const ObjectValueElement = (props: { value: ObjectValue; identLevel: number }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);

  if (!opened)
    return (
      <span onClick={() => setOpened(true)} className={classes.object}>
        {'{}'}
      </span>
    );

  const values = props.value.children.map((child, key) => (
    <div key={key}>
      <Identation level={props.identLevel + 1} />
      {child.name}
      <span className={classes.type}>
        {child.value.type === 'object' && child.value.typeName ? ': ' + child.value.typeName : ''}
      </span>
      : <ValueElement value={child.value} identLevel={props.identLevel + 1}></ValueElement>
    </div>
  ));

  return (
    <span className={classes.object}>
      <span onClick={() => setOpened(false)}>{'{'}</span>
      {values}
      {values.length > 0 ? (
        <div>
          <Identation level={props.identLevel} />
          {'}'}
        </div>
      ) : (
        <span>{'}'}</span>
      )}
    </span>
  );
};

const ObservableValueElement = ({ value, identLevel }: { value: ObservableValue; identLevel: number }) => {
  const currentValue = useObservable(value.value$);
  console.log(convertToValue(currentValue));

  const a = convertToValue(currentValue);
  return <ValueElement value={a} identLevel={identLevel} />;
};

export const ValueElement = (props: { value: Value; identLevel: number }) => {
  if (props.value.type === 'observable')
    return <ObservableValueElement value={props.value} identLevel={props.identLevel}></ObservableValueElement>;

  return props.value.type === 'object' ? (
    <ObjectValueElement value={props.value} identLevel={props.identLevel} />
  ) : (
    <PrimitiveValueElement value={props.value} />
  );
};

export type ReflectedSchema =
  | { type: 'string' | 'boolean' | 'number' }
  | { type: 'object'; typeName: string; children: Array<{ name: string; value: ReflectedSchema }> };

const reifySchema = (schema: ReflectedSchema, value: any): Value => {
  if (schema.type === 'object') {
    return {
      type: 'object',
      typeName: schema.typeName,
      children: schema.children.map(({ name, value: subSchema }) => ({
        name,
        value: reifySchema(subSchema, value[name]),
      })),
    };
  } else {
    return { type: schema.type, value };
  }
};

const convertToValue = (value: unknown): EagerValue => {
  let type = typeof value;
  if (type !== 'string' && type !== 'boolean' && type !== 'number' && type !== 'object' && type !== 'undefined')
    throw new Error('Invalid type to reflect');

  if (type === 'undefined' || (type === 'object' && !value)) type = 'symbol';

  if (type === 'object') {
    return {
      type: 'object',
      typeName: (value as object)?.constructor?.name,
      children: Object.entries(value as object).map(([name, child]) => ({
        name,
        value: convertToValue(child),
      })),
    };
  } else {
    return {
      type,
      value: value as any,
    };
  }
};

class ABC {
  a: string = 'AAA';
  b: number = 1234;
  c: {
    ident: number;
  } = { ident: 1 };
}

export const ReflectorSideBar = () => {
  const playbackService = useInjection(PlayBackService);

  const value: ObjectValue = {
    type: 'object',
    typeName: 'PlaybackService',
    children: [
      {
        name: 'state',
        value: {
          type: 'observable',
          value$: playbackService.playBackState$,
        },
      },

      {
        name: 'time',
        value: {
          type: 'observable',
          value$: playbackService.time$,
        },
      },
    ],
  };

  return (
    <Drawer variant="permanent" open={true}>
      <ValueElement value={value} identLevel={0}></ValueElement>
    </Drawer>
  );
};
