import { Fragment, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Grid, IconButton, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close, CloudDone, CloudUpload } from '@material-ui/icons';

import logo from 'assets/images/tntxlogo.png';

interface DialogProps {
  open: boolean;
  accepted: boolean;
  dropZoneText: string;
  fileTypesAllowed: string[];
  handleCloseDialog: () => void;
  handleDropAccepted: (files: File[]) => Promise<void>;
  handleDropFailure: () => void;
  handleSubmitFiles: () => Promise<void>;
}

export type SnackbarVariant = 'error' | 'success' | 'info' | 'warning' | undefined;

// interface SnackBarState {
//   open: boolean;
//   message: string;
//   variant: SnackbarVariant;
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    dialogButton: {
      marginLeft: 10,
    },
    dialogButtonGrid: {
      marginTop: 5,
      marginBottom: 10,
      padding: 5,
    },
    dialogContent: {
      border: 'dashed',
      borderRadius: 5,
      margin: 5,
      padding: 20,
    },
    dialogIcon: {
      margin: 35,
      transform: 'scale(2)',
    },
    dialogLogo: {
      height: '3em',
    },
    dialogText: {
      minWidth: 80,
    },
    dialogTextContainer: {
      minWidth: 400,
      minHeight: 250,
    },
    dialogTitle: {
      fontWeight: 400,
      marginLeft: 10,
      justifyContent: 'left',
    },
    dialogTitleGrid: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: 5,
    },
  })
);

const UploadDialog = ({
  open,
  accepted,
  dropZoneText,
  fileTypesAllowed,
  handleCloseDialog,
  handleDropAccepted,
  handleDropFailure,
  handleSubmitFiles,
}: DialogProps) => {
  const classes = useStyles();
  const [snackBarState, setSnackBackState] = useState({
    open: false,
    message: '',
    variant: 'success' as SnackbarVariant,
  });

  const handleCloseSnackBar = () => {
    setSnackBackState({
      ...snackBarState,
      open: false,
    });
  };

  const handleDropRejected = (fileRejections: FileRejection[]) => {
    setSnackBackState({
      open: true,
      message: fileRejections[0].errors[0].message,
      variant: 'error' as SnackbarVariant,
    });
    handleDropFailure();
  };

  return (
    <Fragment>
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseDialog}
        className={classes.dialog}
        maxWidth="sm"
      >
        <Grid className={classes.dialogTitleGrid} container justifyContent="space-between">
          <img alt="company logo" src={logo} className={classes.dialogLogo} />
          <IconButton>
            <Close />
          </IconButton>
        </Grid>
        <DialogContent className={classes.dialogContent}>
          <Dropzone
            accept={fileTypesAllowed}
            multiple={false}
            onDropAccepted={handleDropAccepted}
            onDropRejected={handleDropRejected}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={classes.dialogTextContainer}>
                    <Typography variant="h5" component="p" className={classes.dialogText}>
                      {accepted ? 'File accepted' : dropZoneText}
                    </Typography>
                    {accepted ? (
                      <CloudDone className={classes.dialogIcon} />
                    ) : (
                      <CloudUpload className={classes.dialogIcon} />
                    )}
                  </div>
                  <p></p>
                </div>
              </section>
            )}
          </Dropzone>
        </DialogContent>
        <Grid className={classes.dialogButtonGrid} container justifyContent="flex-end">
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setSnackBackState({
                open: true,
                message: `[${dropZoneText}]: Operation Cancelled`,
                variant: 'warning' as SnackbarVariant,
              });
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={async () => {
              handleSubmitFiles().then(() => {
                setSnackBackState({
                  open: true,
                  message: `[${dropZoneText}]: Operation Successful`,
                  variant: 'success' as SnackbarVariant,
                });
              });
            }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Dialog>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        open={snackBarState.open}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={snackBarState.variant}>{snackBarState.message}</Alert>
      </Snackbar>
    </Fragment>
  );
};

export default UploadDialog;
