import { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getStatus, selectStatus } from './authenticationSlice';
import SignIn from './SignIn';
import SignOut from './SignOut';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authButton: {
      minWidth: '60px',
      position: 'absolute',
      right: '0px',
    },
  })
);

const AuthButton = () => {
  const classes = useStyles();
  const isAuthenticated = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch, isAuthenticated]);

  return <div className={classes.authButton}>{isAuthenticated ? <SignOut /> : <SignIn />}</div>;
};

export default AuthButton;
