import { interfaces } from 'inversify';
import { useContext, useEffect, useState } from 'react';

import { IOCContext } from 'features/ioc/IOCProvider';
import StateObservable from 'features/state/stateObservable';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { getRawObservable, StateConsumer } from 'features/state';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useObservable = <T>(observable: StateConsumer<T>) => {
  const raw = getRawObservable(observable);

  const [state, setState] = useState(raw._getCurrent());

  useEffect(() => {
    const observer = observable.add(setState);
    return () => {
      observable.remove(observer);
    };
  }, []);

  return state;
};

export const useInjection = <T>(identifier: interfaces.ServiceIdentifier<T>) => {
  const { container } = useContext(IOCContext);
  if (!container) {
    throw new Error('useInjection: container context does not exist');
  }
  return container.get<T>(identifier);
};
