import { PropsWithChildren, useReducer } from 'react';
import { makeStyles, createStyles, CssBaseline } from '@material-ui/core';

// import Footer from './Footer';
import Header from './Header';
// import Navigation from './Navigation';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      boxShadow: 'none',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      margin: 0,
      padding: 0,
    },
    footer: {},
  })
);

const Layout = (props: PropsWithChildren<{}>) => {
  const classes = useStyles();
  const [open, toggleOpen] = useReducer(open => !open, true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={open} handleMenuOpen={toggleOpen} />
      {/* <Navigation open={open} handleMenuClose={toggleOpen} /> */}
      <main className={classes.content}>{props.children}</main>
      <footer className={classes.footer}>{/* <Footer /> */}</footer>
    </div>
  );
};

export default Layout;
