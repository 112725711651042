// APP TEXT
export const APP_TITLE = 'STSA';
export const FOOTER_TEXT = `${new Date().getFullYear()} ©TNTX All Rights Reserved`;
// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_BIOMETRICS = 'Biometrics';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_SETTINGS = 'Settings';
export const PAGE_TITLE_TERRAIN = 'Terrain';
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
