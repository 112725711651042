import 'reflect-metadata';
import { inject, injectable } from 'inversify';

import { getAnnotations } from 'api/axios';
import { Annotation } from 'interfaces/annotation.interface';
import StateProducer from 'features/state';
import { Logger } from './logger.service';

@injectable()
export default class AnnotationsService {
  @inject(Logger) public logger!: Logger;

  private _annotations$ = new StateProducer([] as Annotation[]);
  public get annotations$() {
    return this._annotations$.consumer;
  }

  constructor() {}

  public async fetch(terrainId: string) {
    this.logger.info(`Fetch annotations from server\n where terrainId="${terrainId}"`);

    const annotations = await getAnnotations(terrainId);

    this._annotations$.value = annotations;
  }

  public updateAnnotationById(annotation: Annotation) {
    this.logger.info(`Update annotation "${annotation.annotationId} -> "`, annotation);

    const annoationIndex = annotation.annotationId;

    const index = this._annotations$.value.findIndex(annotation => annotation.annotationId === annoationIndex);
    this._updateAnnotationByArrayIndex(index, annotation);
  }

  private _updateAnnotationByArrayIndex(index: number, annotation: Annotation, notifyObservers: boolean = true) {
    if (!Object.hasOwn(this._annotations$.value, index)) {
      this.logger.warn(
        `Tried to update an annotation ${annotation.annotationId} which should be ${index} in annotation array, but it is not present.`
      );
      return;
    }
    this._annotations$.value[index] = annotation;
    if (notifyObservers) this._annotations$.forceNotifyObservers();
  }
}
