import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface MiniMapState {
  enable: boolean;
}

const initialState: MiniMapState = {
  enable: true,
};

const miniMapSlice = createSlice({
  name: 'view/miniMap',
  initialState,
  reducers: {
    enableMiniMap: (state, action: PayloadAction<boolean>) => {
      state.enable = action.payload;
    },
  },
  extraReducers: {},
});

export const { enableMiniMap } = miniMapSlice.actions;

export const selectMiniMap = (state: RootState) => state.miniMap.enable;

export default miniMapSlice.reducer;
