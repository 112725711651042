import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import reduxWiretap from 'redux-wiretap';

import athleteReducer from 'features/athlete/athleteSlice';
import biometricsReducer from 'features/biometrics/biometricsSlice';
import authenticationReducer from 'features/authentication/authenticationSlice';
import darkModeReducer from 'features/darkMode/darkModeSlice';
import gameCanvasReducer from 'features/gameCanvas/gameCanvasSlice';
import hudReducer from 'features/hud/hudSlice';
import courseReducer from 'features/course/courseSlice';
import localeReducer from 'features/locale/localeSlice';
import locationReducer from 'features/location/locationSlice';
import miniMapReducer from 'features/miniMap/miniMapSlice';
import meshLabelReducer from 'features/meshLabel/meshLabelSlice';
import racePathReducer from 'features/paths/pathSlice';
import skiGateReducer from 'features/gates/gateSlice';
import terrainReducer from 'features/terrain/terrainSlice';
import xrReducer from 'features/xrMode/xrModeSlice';

import persistConfig from 'features/persist/persistConfig';

const rootReducer = combineReducers({
  athlete: athleteReducer,
  authentication: authenticationReducer,
  biometrics: biometricsReducer,
  course: courseReducer,
  darkMode: darkModeReducer,
  gameCanvas: gameCanvasReducer,
  hud: hudReducer,
  locale: localeReducer,
  location: locationReducer,
  meshLabel: meshLabelReducer,
  miniMap: miniMapReducer,
  racePath: racePathReducer,
  skiGate: skiGateReducer,
  terrain: terrainReducer,
  xrMode: xrReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions,
      },
    }).concat(reduxWiretap),
  // RD: 26/05/22:  The following causes slowdown during playback
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
