import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export interface GameCanvasState {
  scene: string;
}

const initialState: GameCanvasState = {
  scene: '',
};

export const gameCanvasSlice = createSlice({
  name: 'gameCanvas',
  initialState,
  reducers: {
    setScene: (state, action: PayloadAction<string>) => {
      state.scene = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setScene } = gameCanvasSlice.actions;

export const selectScene = (state: RootState) => state.gameCanvas.scene;

export default gameCanvasSlice.reducer;
