import { Annotation } from 'interfaces/annotation.interface';
import { get } from './request.axios';

const apiPath = `/annotations`;
const apiSubPath = `/terrain`;

export const getAnnotation = async (annotationId: string) => {
  return get<{ data: Annotation }>(`${apiPath}/${annotationId}`).then(response => {
    return response.data;
  });
};

export const getAnnotations = async (terrainId: string) => {
  return get<{ data: Annotation[] }>(`${apiSubPath}/${terrainId}/annotations`).then(response => {
    return response.data;
  });
};
