import { useEffect } from 'react';

import { getPaths } from 'api/axios';
import { selectTerrain } from 'features/terrain/terrainSlice';
import { selectRacePath, selectRacePaths, setRacePaths } from './pathSlice';
import { useAppSelector, useAppDispatch, useInjection, useObservable } from 'app/hooks';
import RacePathService from 'services/racePath.service';
import TerrainService from 'services/terrain.service';

const PathsContainer = () => {
  // const current = useAppSelector(selectRacePath);
  // const racePaths = useAppSelector(selectRacePaths);
  // const terrain = useAppSelector(selectTerrain);
  // const dispatch = useAppDispatch();

  const pathService = useInjection(RacePathService);
  const terrainService = useInjection(TerrainService);
  const current = useObservable(pathService.racePath$);
  const terrain = useObservable(terrainService.currentTerrain$);

  return null;
};

export default PathsContainer;
