import { Observable } from '@babylonjs/core';

export default class StateObservable<T> extends Observable<T> {
  private _equals: (lhs: T, rhs: T) => boolean;
  private _clone: (state: T) => T;

  private _value: T;

  constructor(
    initial: T,
    equals: (lhs: T, rhs: T) => boolean = (lhs: T, rhs: T) => lhs === rhs,
    clone: (state: T) => T = state => state
  ) {
    super(observer => {
      this.notifyObserver(observer, this._value);
    });
    this._equals = equals;
    this._clone = clone;
    this._value = initial;
  }

  public notifyObservers(
    newState: T,
    mask?: number | undefined,
    target?: any,
    currentTarget?: any,
    userInfo?: any
  ): boolean {
    if (this._equals(newState, this._value)) return false;
    this._value = this._clone(newState);
    return super.notifyObservers(this._value, mask, target, currentTarget, userInfo);
  }

  public forceNotifyObserversWithValue(
    mask?: number | undefined,
    target?: any,
    currentTarget?: any,
    userInfo?: any
  ): boolean {
    return super.notifyObservers(this._value, mask, target, currentTarget, userInfo);
  }

  /**
   * @warning This method is strongly discouraged in favor of subscribing to the observable if you do not own the observable
   * @returns the current value
   */
  public _getCurrent(): T {
    return this._value;
  }
}
