import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Biometric } from 'interfaces/biometric.interface';
import { RootState } from 'app/store';

export interface BiometricState {
  biometrics: Biometric[];
}

const initialState: BiometricState = {
  biometrics: [],
};

export const biometricSlice = createSlice({
  name: 'biometrics',
  initialState,
  reducers: {
    setBiometrics: (state, action: PayloadAction<Biometric[]>) => {
      state.biometrics = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setBiometrics } = biometricSlice.actions;

export const selectBiometrics = (state: RootState) => state.biometrics.biometrics;

export default biometricSlice.reducer;
