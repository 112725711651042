import { v4 as uuidv4 } from 'uuid';
import { parse } from '@fast-csv/parse';

import { useState } from 'react';

import UploadDialog from 'components/UploadDialog';
import { RacePath } from 'interfaces/racePath.interface';
import { RacePathPoint } from 'interfaces/racePathPoint.interface';

import { setRacePath } from './pathSlice';
import { useAppDispatch, useInjection } from 'app/hooks';
import RacePathService from 'services/racePath.service';

interface FileState {
  file?: File;
  status: boolean;
}

interface RacePathDialogProps {
  open: boolean;
  handleCloseDialog: () => void;
}

interface RacePathFileEntry {
  distance: number;
  elevation: number;
  latitude: number;
  longitude: number;
  speed: number;
  time: number | Date;
}

const racePathHeaders = ['distance', 'elevation', 'latitude', 'longitude', 'speed', 'time'];
const _parseHeaders = (headers: string[]): boolean => {
  let retVal = true;
  const matchKeys = headers.map(h => {
    return h.substring(0, h.lastIndexOf('[')).toLocaleLowerCase();
  });
  racePathHeaders.forEach(h => {
    if (!matchKeys.includes(h)) {
      retVal = false;
    }
  });
  return retVal;
};

const _setProperty = <T, K extends keyof T>(obj: T, key: K, value: T[K]) => {
  obj[key] = value;
};

const _stripMatchJson = (obj: Object): RacePathFileEntry => {
  const retObj: RacePathFileEntry = {
    distance: 0,
    elevation: 0,
    latitude: 0,
    longitude: 0,
    speed: 0,
    time: 0,
  };

  Object.entries(obj).map(([key, value]) => {
    if (value !== 'nan') {
      const matchKey = key.substring(0, key.lastIndexOf('[')).toLowerCase();
      _setProperty(
        retObj,
        matchKey as keyof RacePathFileEntry,
        value.includes('.') ? parseFloat(value) : value.includes('T') ? _formatTime(value) : parseInt(value)
      );
    }
  });

  return retObj;
};

const _formatTime = (date: string): Date => {
  if (date.includes('-') && date.includes(':')) {
    return new Date(date);
  }
  return new Date(
    `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 11)}:${date.substring(
      11,
      13
    )}:${date.substring(13, 15)}.${date.substring(15)}Z`
  );
};

const UploadRacePathDialog = ({ open, handleCloseDialog }: RacePathDialogProps) => {
  const dropZoneText = 'Upload Race Path';
  const [fileState, setFileState] = useState({
    status: false,
  } as FileState);

  const [fileAccepted, setFileAccepted] = useState(false);
  const dispatch = useAppDispatch();

  const racePathService = useInjection(RacePathService);

  const handleCreateRacePath = (racePathFileEntries: RacePathFileEntry[]) => {
    if (racePathFileEntries.length) {
      const startTime = racePathFileEntries[0].time instanceof Date ? racePathFileEntries[0].time.getTime() : undefined;
      const lastPoint = racePathFileEntries[racePathFileEntries.length - 1];

      let sMax = Number.MIN_VALUE;
      const racePath: RacePath = {
        racePathId: uuidv4(),
        distanceTotal: lastPoint.distance,
        elevationTotal: racePathFileEntries[0].elevation,
        speedMaximum: 0,
        timeSecTotal: startTime ? ((lastPoint.time as Date).getTime() - startTime) / 1000 : (lastPoint.time as number),
        racePathPoints: racePathFileEntries.map((pt, idx) => {
          sMax = pt.speed > sMax ? pt.speed : sMax;
          return {
            racePathPointIndex: idx,
            distance: pt.distance,
            acceleration: 0,
            speed: pt.speed,
            time_sec: startTime ? ((pt.time as Date).getTime() - startTime) / 1000 : (pt.time as number),
            isStart: idx === 0,
            isEnd: idx === racePathFileEntries.length - 1,
            location: {
              gameCoordinate: {
                x: 0,
                y: 0,
                z: 0,
              },
              worldCoordinate: {
                latitude: pt.latitude,
                longitude: pt.longitude,
                elevation: pt.elevation,
              },
            },
          } as RacePathPoint;
        }),
      };
      racePath.speedMaximum = sMax;
      // dispatch(setRacePath(racePath));
      racePathService.racePath$.set(racePath);
    }
  };

  const handleDropAccepted = async (files: File[]) => {
    files.forEach(f => {
      f.text().then(contents => {
        const stream = parse({ headers: true }).on('headers', headers => {
          if (_parseHeaders(headers)) {
            setFileState({
              file: f,
              status: true,
            });
          }
        });

        stream.write(contents);
      });
    });
  };

  const handleDropFailure = () => {
    setFileAccepted(false);
  };

  const handleSubmitFiles = async () => {
    if (fileState.file) {
      const racePathFileEntries: RacePathFileEntry[] = [];

      fileState.file.text().then(contents => {
        const stream = parse({ headers: true })
          .on('error', error => console.error(error))
          .on('data', row => {
            const fileEntry = _stripMatchJson(row);
            racePathFileEntries.push(fileEntry);
          });
        stream.write(contents, () => {
          handleCreateRacePath(racePathFileEntries);
        });
      });
    }
  };

  return (
    <UploadDialog
      open={open}
      accepted={fileState.status}
      dropZoneText={dropZoneText}
      fileTypesAllowed={[
        '.csv',
        'text/csv',
        'text/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values',
      ]}
      handleCloseDialog={handleCloseDialog}
      handleDropAccepted={handleDropAccepted}
      handleDropFailure={handleDropFailure}
      handleSubmitFiles={handleSubmitFiles}
    />
  );
};

export default UploadRacePathDialog;
