import { Fragment, MouseEvent, PropsWithChildren } from 'react';
import { Avatar, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppDispatch, useInjection, useObservable } from '../../app/hooks';
import LocaleService from 'services/locale.service';

import { IHudControl } from 'features/hud/HudControls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerButton: {
      border: `0.1px solid`,
      height: '25px',
      width: '25px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    headerText: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      marginLeft: 15,
      justifyContent: 'flex-start',
    },
    menuButton: {
      height: '45px',
      width: '45px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

const LocaleHeader = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const localeService = useInjection(LocaleService);
  const current = useObservable(localeService.currentLocale$);

  const handleToggleHud = (e: MouseEvent<HTMLElement>) => {
    props.setHudEnabled(false);
  };

  return (
    <Fragment>
      <Avatar
        id="locale-select-button"
        className={classes.headerButton}
        alt=""
        src={current && current.iconUri ? current.iconUri : ''}
        style={{ borderColor: current && current.iconColor ? current.iconColor : 'black' }}
        onClick={handleToggleHud}
      ></Avatar>
      <Button className={classes.menuButton} onClick={handleToggleHud} disableRipple>
        <Typography className={classes.headerText} variant="h5">
          {current ? current.localeCode : 'Select Location'}
        </Typography>
      </Button>
    </Fragment>
  );
};

export default LocaleHeader;
