import { useEffect } from 'react';

import CourseService from 'services/course.service';
import TerrainService from 'services/terrain.service';
import { useInjection, useObservable } from 'app/hooks';

const TerrainContainer = () => {
  const courseService = useInjection(CourseService);
  const terrainService = useInjection(TerrainService);

  const course = useObservable(courseService.currentCourse$);

  useEffect(() => {
    if (course?.courseId) {
      terrainService.fetchAll(course.courseId);
    }
  }, [course]);

  return null;
};

export default TerrainContainer;
