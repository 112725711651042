import {
  Box,
  Card,
  CardContent,
  createStyles,
  Divider,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useInjection, useObservable } from 'app/hooks';
import CameraService from 'services/camera.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      backdropFilter: 'blur(5px)',
      position: 'fixed',
      top: '10%',
      right: '5%',
      marginLeft: '-140px',
      width: '280px',
    },
    menuCard: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    menuContent: {
      flexGrow: 1,
      padding: 10,
    },
    menuTitle: {
      color: theme.palette.primary.main,
    },
    listDivider: {
      background: theme.palette.primary.main,
      margin: 15,
      opacity: 0.8,
    },
    listItem: {
      color: theme.palette.primary.main,
    },
  })
);

const HudCameraSelector = () => {
  const classes = useStyles();

  const cameraService = useInjection(CameraService);
  const availableCameras = useObservable(cameraService.availableCameras$);
  const currentCamera = useObservable(cameraService.currentCamera$);

  const CameraItem = (camera: { name: string; id: string }) => (
    <ListItem
      key={camera.id}
      className={classes.listItem}
      selected={camera.id === currentCamera?.id}
      onClick={() => cameraService.setCurrent(camera.id)}
      button
    >
      <CameraAlt style={{ marginRight: '10px' }} />
      {camera.name}
    </ListItem>
  );

  return (
    <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center">
      <Card className={classes.menuCard} elevation={0}>
        <CardContent className={classes.menuContent}>
          <Box alignItems="center" display="flex" flexDirection="column" justifyContent="left" width="auto">
            <Box display="block">
              <Typography className={classes.menuTitle} variant="h4">
                Cameras
              </Typography>
            </Box>
            <Divider flexItem className={classes.listDivider} style={{ height: 2 }} variant="fullWidth" />
            <List>{availableCameras.cameras.map(CameraItem)}</List>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HudCameraSelector;
