import { Fragment, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useAppSelector, useInjection } from 'app/hooks';
import { selectHudEnabled } from 'features/hud/hudSlice';

import AnnotationsContainer from 'features/annotations/AnnotationsContainer';
import CourseContainer from 'features/course/CourseContainer';
import GatesContainer from 'features/gates/GatesContainer';
import LocaleContainer from 'features/locale/LocaleContainer';
import PathsContainer from 'features/paths/PathsContainer';
import TerrainContainer from 'features/terrain/TerrainContainer';

import { GameCanvas } from 'features/gameCanvas/GameCanvas';

import TerrainControls from 'features/terrain/TerrainControls';
import HudControls from 'features/hud/HudControls';

import { PAGE_TITLE_TERRAIN } from 'utils/constants';
import PlayBackService from 'services/playBack.service';
import SceneService from 'services/scene.service';
import HudCameraSelector from 'features/hud/HudCameraSelector';

const useStyles = makeStyles(() =>
  createStyles({
    pageRoot: {
      height: '100%',
      width: '100%',
      margin: 'none',
      overflow: 'hidden',
    },
  })
);

const Terrain = () => {
  const classes = useStyles();
  const [hudEnabled, setHudEnabled] = useState(false);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{PAGE_TITLE_TERRAIN}</title>
      </Helmet>
      <Fragment>
        <div className={classes.pageRoot}>
          <AnnotationsContainer />
          <CourseContainer />
          <GatesContainer />
          <LocaleContainer />
          <PathsContainer />
          <TerrainContainer />
          <GameCanvas sceneName={'mountainScene'}>
            {hudEnabled ? (
              <HudControls setHudEnabled={setHudEnabled} />
            ) : (
              <TerrainControls setHudEnabled={setHudEnabled} />
            )}
          </GameCanvas>
        </div>
      </Fragment>
    </HelmetProvider>
  );
};

export default Terrain;
