export const validateEmail = (input: string): boolean => {
  let regEmail = /^[a-zA-Z0-9_.+-]+@(skidor\.com|tntx\.se)$/;
  if (regEmail.test(input)) {
    return true;
  }
  return false;
};

export const validateName = (input: string): boolean => {
  let regName = /^[a-zA-Z]{3,14} [a-zA-Z]{3,14}$/;
  if (regName.test(input)) {
    return true;
  }
  return false;
};

export const validatePassword = (input: string): boolean => {
  let regPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  if (regPassword.test(input)) {
    return true;
  }
  return false;
};
