import { useEffect } from 'react';

import AthleteService from 'services/athlete.service';
import BiometricService from 'services/biometric.service';
import { useObservable, useInjection } from 'app/hooks';

const BiometricContainer = () => {
  const biometricService = useInjection(BiometricService);
  const athleteService = useInjection(AthleteService);

  const athlete = useObservable(athleteService.currentAthlete$);

  useEffect(() => {
    if (athlete?.athleteId) {
      biometricService.fetchAll(athlete.athleteId);
    }
  }, [athlete]);

  return null;
};

export default BiometricContainer;
