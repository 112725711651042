import { cloneElement, Fragment, PropsWithChildren, ReactElement } from 'react';
import { createStyles, makeStyles, useScrollTrigger, AppBar, Button, Theme, Toolbar } from '@material-ui/core';

import AuthButton from 'features/authentication/AuthButton';

import logo from 'assets/images/tntxlogo.png';

const ElevationScroll = (props: PropsWithChildren<{}>) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(props.children as ReactElement, {
    elevation: trigger ? 4 : 0,
  });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBarMargin: {
      ...theme.mixins.toolbar,
    },
    logo: {
      marginLeft: '5px',
      height: '4em',
    },
  })
);

const handleCallAPI = async () => {
  // console.log('CALLED API');
};

interface HeaderProps {
  open: boolean;
  handleMenuOpen: () => void;
}

const Header = ({ open, handleMenuOpen }: HeaderProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <Toolbar disableGutters>
            <Button onClick={handleCallAPI} style={{ backgroundColor: 'transparent' }} disableRipple>
              <img alt="company logo" src={logo} className={classes.logo} />
            </Button>
            <AuthButton />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolBarMargin} />
    </Fragment>
  );
};

export default Header;
