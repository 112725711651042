import axios from 'axios';
import { ResponseWithUser } from 'interfaces/user.interface';
axios.defaults.withCredentials = true;

const apiUrl = process.env.REACT_APP_API_URL;

export const status = async () => {
  return axios.get(`${apiUrl}/status`);
};

export const register = async (email: string, userName: string, userPassword: string) => {
  return axios
    .post<ResponseWithUser>(`${apiUrl}/register`, {
      userName,
      email,
      userPassword,
    })
    .then(response => {
      return response.data;
    });
};

export const login = async (email: string, userPassword: string, userName = '') => {
  return axios
    .post<ResponseWithUser>(`${apiUrl}/login`, {
      userName,
      email,
      userPassword,
    })
    .then(response => {
      return response.data;
    });
};

export const logout = async (email: string, userPassword = '', userName = '') => {
  return axios.post(`${apiUrl}/logout`, {
    userName,
    email,
    userPassword,
  });
};
