import 'reflect-metadata';

import type { Nullable } from '@babylonjs/core';
import { injectable, interfaces, unmanaged } from 'inversify';

@injectable()
export default abstract class LoggerService {
  public defaultMinLevel: LogLevel = LogLevel.WARN;

  public abstract createLogger: (scope: Nullable<string>, minLevel: LogLevel) => Logger;
}

export class ConsoleLoggerService extends LoggerService {
  public createLogger = (scope: Nullable<string>, minLevel: LogLevel): Logger => {
    return new ConsoleLogger(scope, minLevel);
  };
}

export const provideLogger = (context: interfaces.Context) => {
  const scope = (context.plan.rootRequest.bindings[0].implementationType as Function).name;
  const loggerService = context.container.get(LoggerService);
  return loggerService.createLogger(scope, LogLevel.TRACE);
};

export enum LogLevel {
  TRACE,
  INFO,
  WARN,
  ERROR,
}

const formatLevel = (logLevel: LogLevel) =>
  ({
    [LogLevel.TRACE]: 'TRACE',
    [LogLevel.INFO]: 'INFO',
    [LogLevel.WARN]: 'WARN',
    [LogLevel.ERROR]: 'ERROR',
  }[logLevel]);

@injectable()
export abstract class Logger {
  constructor(@unmanaged() public scope: Nullable<string>, @unmanaged() public minLevel: LogLevel) {}

  public trace(...data: any[]) {
    this.log(LogLevel.TRACE, ...data);
  }

  public info(...data: any[]) {
    this.log(LogLevel.INFO, ...data);
  }

  public warn(...data: any[]) {
    this.log(LogLevel.WARN, ...data);
  }

  public error(...data: any[]) {
    this.log(LogLevel.ERROR, ...data);
  }

  public log(level: LogLevel, ...data: any[]): void {
    if (level >= this.minLevel) return this.logIgnoreMinLevel(level, ...data);
  }

  public abstract logIgnoreMinLevel(level: LogLevel, ...data: any[]): void;
}

@injectable()
export class ConsoleLogger extends Logger {
  public logIgnoreMinLevel(level: LogLevel, ...data: any[]): void {
    switch (level) {
      case LogLevel.ERROR: {
        console.error(...this._formatScope(level), ...data);
        break;
      }
      case LogLevel.WARN: {
        console.warn(...this._formatScope(level), ...data);
        break;
      }
      default: {
        console.log(...this._formatScope(level), ...data);
        break;
      }
    }
  }

  private _levelColor(level: LogLevel): string {
    return {
      [LogLevel.TRACE]: 'gray',
      [LogLevel.INFO]: 'inherit',
      [LogLevel.WARN]: 'inherit',
      [LogLevel.ERROR]: 'inherit',
    }[level];
  }

  private _formatScope(level: LogLevel) {
    return [
      `%c${formatLevel(level)}%c${this.scope ? ` in %c${this.scope}` : '%c'}:\n`,
      `font-weight: bolder;color: ${this._levelColor(level)}`,
      'font-weight: inherit; color: inherit;',
      'font-weight: bolder; color:#add8e6',
    ];
  }
}
