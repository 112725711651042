import { PropsWithChildren } from 'react';
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TerrainSelection from './TerrainSelection';
import TerrainMenu from './TerrainMenu';
import { IHudControl } from 'features/hud/HudControls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: 'transparent',
      margin: 0,
      maxHeight: '100%',
    },
  })
);

const TerrainControls = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <TerrainSelection />
      <TerrainMenu setHudEnabled={props.setHudEnabled} />
    </Paper>
  );
};

export default TerrainControls;
