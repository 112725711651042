import { ChangeEvent, Fragment, KeyboardEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Theme,
  Divider,
} from '@material-ui/core';
import UserIcon from '@material-ui/icons/AccountCircle';
import { LockOutlined } from '@material-ui/icons';

import { loginUser } from './authenticationSlice';
import { validateEmail, validatePassword } from './validation';
import Register from './Register';

import { useAppDispatch } from 'app/hooks';

import logo from 'assets/images/tntxlogo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '25px',
      marginBottom: '25px',
    },
    dialog: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      marginRight: '5px',
      transform: 'scale(1.8)',
    },
    logo: {
      height: '7em',
    },
    loginButton: {
      marginTop: '10px',
      marginBottom: '15px',
    },
    registerGrid: {
      marginTop: '5px',
      marginBottom: '25px',
    },
  })
);

interface ITextField {
  formEmail: string;
  formPassword: string;
}

const SignIn = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const [inputValues, setInputValues] = useState<ITextField>({
    formEmail: '',
    formPassword: '',
  });

  const handleLoginUser = async () => {
    await dispatch(
      loginUser({
        email: `${inputValues.formEmail}`,
        userPassword: `${inputValues.formPassword}`,
      })
    );
    history.push('/terrain');
    handleCloseDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenRegistration = () => {
    setOpenRegistration(true);
    setOpenDialog(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;
    setInputValues(prevState => ({ ...prevState, [id]: value }));
  };

  const handleEnterKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!(validateEmail(inputValues?.formEmail) || validatePassword(inputValues?.formPassword))) {
        return;
      }
      handleLoginUser();
    }
  };

  return (
    <Fragment>
      <IconButton
        className={classes.iconButton}
        color="secondary"
        disableFocusRipple
        disableRipple
        size="medium"
        onClick={handleOpenDialog}
      >
        <UserIcon />
      </IconButton>
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openDialog}
        onClose={handleCloseDialog}
        onKeyPress={handleEnterKeyPressed}
        className={classes.dialog}
        maxWidth="sm"
      >
        <DialogContent>
          <img alt="company logo" src={logo} className={classes.logo} />
          <DialogContentText>STSA Client Authentication</DialogContentText>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <TextField
            onChange={handleInputChange}
            margin="dense"
            id="formEmail"
            label="Email"
            type="email"
            value={inputValues?.formEmail}
            fullWidth={true}
            variant="outlined"
          />
          <TextField
            onChange={handleInputChange}
            margin="dense"
            id="formPassword"
            label="Password"
            type="password"
            value={inputValues?.formPassword}
            fullWidth={true}
            variant="outlined"
          />
          <Button
            className={classes.loginButton}
            onClick={handleLoginUser}
            color="primary"
            fullWidth={true}
            variant="contained"
          >
            Sign In
          </Button>
          <Divider variant="fullWidth" />
          <Grid className={classes.registerGrid} container justifyContent="space-between">
            <Button variant="text">Forgot Password?</Button>
            <Button variant="text" onClick={handleOpenRegistration}>
              Sign Up?
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Register openDialog={openRegistration} setOpenDialog={setOpenRegistration} />
    </Fragment>
  );
};

export default SignIn;
