import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoordinateWorld } from 'interfaces/coordintate.interface';
import { RootState } from 'app/store';

export interface LocationState {
  location: CoordinateWorld | null;
  showDialog: boolean;
}

const initialState: LocationState = {
  location: null,
  showDialog: false,
};

export const locationSlice = createSlice({
  name: 'ui/location',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<CoordinateWorld>) => {
      state.location = action.payload;
    },
    toggleDialog: state => {
      state.showDialog = !state.showDialog;
    },
  },
  extraReducers: {},
});

export const { setLocation, toggleDialog } = locationSlice.actions;

export const selectLocation = (state: RootState) => state.location.location;
export const selectDialogToggle = (state: RootState) => state.location.showDialog;

export default locationSlice.reducer;
