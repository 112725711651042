import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Course } from 'interfaces/course.interface';
import { RootState } from 'app/store';

export interface CourseState {
  course: Course | null;
  courses: Course[];
}

const initialState: CourseState = {
  course: null,
  courses: [],
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<Course | null>) => {
      state.course = action.payload;
    },
    setCourses: (state, action: PayloadAction<Course[]>) => {
      state.courses = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setCourse, setCourses } = courseSlice.actions;

export const selectCourse = (state: RootState) => state.course.course;
export const selectCourses = (state: RootState) => state.course.courses;

export default courseSlice.reducer;
