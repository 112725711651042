import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Terrain } from 'interfaces/terrain.interface';
import { RootState } from 'app/store';

export interface TerrainState {
  terrain: Terrain | null;
  terrains: Terrain[];
}

const initialState: TerrainState = {
  terrain: null,
  terrains: [],
};

export const terrainSlice = createSlice({
  name: 'terrain',
  initialState,
  reducers: {
    setTerrain: (state, action: PayloadAction<Terrain>) => {
      state.terrain = action.payload;
    },
    setTerrains: (state, action: PayloadAction<Terrain[]>) => {
      state.terrains = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setTerrain, setTerrains } = terrainSlice.actions;

export const selectTerrain = (state: RootState) => state.terrain.terrain;
export const selectTerrains = (state: RootState) => state.terrain.terrains;

export default terrainSlice.reducer;
