import 'reflect-metadata';

import { inject, injectable } from 'inversify';
import StateProducer from 'features/state';
import { SkiGate } from 'interfaces/skiGate.interface';

import { getGates } from 'api/axios';
import { Logger } from './logger.service';
import { updateGates } from 'api/axios/gates.axios';

@injectable()
export default class SkiGatesService {
  @inject(Logger) public logger!: Logger;

  private _gates$ = new StateProducer([] as SkiGate[]);
  public get gates$() {
    return this._gates$.consumer;
  }

  public get skiGates() {
    return this._gates$.value;
  }

  constructor() {}

  public async fetch(terrainId: string) {
    this.logger.info(`Fetch ski gates from server\n where terrainId="${terrainId}"`);

    const gates = await getGates(terrainId);

    if (gates.length === 0) {
      this.logger.warn(`No gates were found\n where terrainId="${terrainId}.`);
    }

    this.logger.trace('Fetched gates', gates);

    this._gates$.value = gates;
  }

  /**  Update a subset of all gates with given ids
   In pseudocode it works rougly this way

   ```
   for (const updatedGate of gates) {
    const idx = index such that this.gates[index].skiGateId == updatedGate.skiGateId
    this.gates[idx] = updatedGates
   }
   notify observers for gates
   ```
  */

  public async updateDB() {
    this.logger.info(`Update ski gates on server\n`);

    await updateGates(this._gates$.value);
  }

  public updateGatesById(gates: SkiGate[]) {
    // This is faster than doing a bunch of updateGateById because that one is O(n) and doing that in a loop would result in O(n^2)
    const ids = gates.map(gate => gate.skiGateId!);
    const gatesById = Object.fromEntries(gates.map(gate => [gate.skiGateId!, gate]));

    this.logger.info('Update gates: ', gatesById);

    const indiciesById = Object.fromEntries(
      this._gates$.value
        .map((gate, index) => [gate.skiGateId!, index] as [string, number])
        .filter(([id, index]) => id in gatesById)
    );

    for (const id of ids) {
      this._gates$.value[indiciesById[id]] = gatesById[id];
    }
    this._gates$.forceNotifyObservers();
  }

  public updateGateById(gate: SkiGate) {
    this.logger.info(`Update gate "${gate.skiGateId} -> "`, gate);

    const gateIndex = gate.skiGateId;

    const index = this._gates$.value.findIndex(gate => gate.skiGateId === gateIndex);
    this._updateGateByArrayIndex(index, gate);
  }

  private _updateGateByArrayIndex(index: number, gate: SkiGate, notifyObservers: boolean = true) {
    if (!Object.hasOwn(this._gates$.value, index)) {
      this.logger.warn(
        `Tried to update an gate ${gate.skiGateIndex} which should be ${index} in gate array, but it is not present.`
      );
      return;
    }
    this._gates$.value[index] = gate;
    if (notifyObservers) this._gates$.forceNotifyObservers();
  }
}
