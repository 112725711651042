import { PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';

import LocaleHeader from 'features/locale/LocaleHeader';
import CourseHeader from 'features/course/CourseHeader';
import PlayBackService from 'services/playBack.service';
import RacePathService from 'services/racePath.service';
import { useObservable, useInjection } from 'app/hooks';

import { IHudControl } from './HudControls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      backdropFilter: 'blur(5px)',
      position: 'fixed',
      top: '10%',
      left: '5%',
      marginRight: '-140px',
      width: '280px',
    },
    menuCard: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    menuContent: {
      flexGrow: 1,
      padding: 10,
    },
    menuTitle: {
      color: theme.palette.primary.main,
    },
    statsDivider: {
      background: theme.palette.primary.main,
      margin: 15,
      opacity: 0.8,
    },
    statsDividerVert: {
      background: theme.palette.primary.main,
      height: 35,
      width: 2,
      marginLeft: 15,
      marginTop: 10,
    },
    statsEntrySep: {
      color: theme.palette.primary.main,
      fontWeight: 300,
      marginLeft: 15,
      marginRight: 10,
      width: 10,
    },
    statsEntryText: {
      color: theme.palette.primary.main,
      width: 60,
    },
    statsEntryTitle: {
      color: theme.palette.primary.main,
      fontWeight: 300,
      marginLeft: 10,
      width: 50,
    },
    statsIcon: {
      marginLeft: '45px',
      border: '0.1px solid #FBDF00',
    },
    statsTimeTitle: {
      fontWeight: 300,
      color: theme.palette.primary.main,
    },
  })
);

const HudRaceStats = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();

  const playbackService = useInjection(PlayBackService);
  // const playbackTotals = useObservable(playbackService.playbackTotals$);
  // const playbackFrame = useObservable(playbackService.playbackFrame$);

  const racePathService = useInjection(RacePathService);
  const racePath = useObservable(racePathService.racePath$);

  // const racePathPoints = useObservable(racePathService.racePathPointTimes$);

  const currentPoint = useObservable(playbackService.currentPoint$);

  // const handleButtonPush = () => {};

  // const handleClickIcon = () => {};

  const statEntryWithTitle = (title: string, entry: string, total: string) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="right" width="auto">
        <Typography className={classes.statsEntryTitle} variant="subtitle2">
          {title}
        </Typography>
        <Typography className={classes.statsEntryText} variant="h5">
          {entry}
        </Typography>
        <Typography className={classes.statsEntrySep} variant="h5">
          /
        </Typography>
        <Typography className={classes.statsEntryText} variant="h5">
          {total}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center">
      <Card className={classes.menuCard} elevation={0}>
        <CardContent className={classes.menuContent}>
          <Typography className={classes.menuTitle} style={{ marginLeft: 30 }} variant="h5">
            Felix
          </Typography>
          <Box alignItems="center" display="flex" flexDirection="row" justifyContent="left" width="auto">
            <Typography className={classes.menuTitle} style={{ fontWeight: 500, marginLeft: 30 }} variant="h3">
              MONSEN
            </Typography>
          </Box>
          <Divider flexItem className={classes.statsDivider} style={{ height: 2 }} variant="fullWidth" />
          <Box
            className={classes.menuTitle}
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="auto"
          >
            <Typography className={classes.statsTimeTitle} variant="subtitle2">
              {/* {racePath?.dateTime?.toDateString()} || [ 2022/01/18 21:34:18 ] */}[ 2022/01/18 21:34:18 ]
            </Typography>
          </Box>
          <Box
            className={classes.menuContent}
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="left"
            width="auto"
          >
            <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
              {statEntryWithTitle(
                'TIME',
                currentPoint?.time_sec?.toFixed(2) || '0',
                racePath?.timeSecTotal?.toFixed(2) || '0'
              )}
              {statEntryWithTitle(
                'DIST',
                currentPoint?.distance?.toFixed(2) || '0',
                racePath?.distanceTotal?.toFixed(2) || '0'
              )}
              {/* {statEntryWithTitle(
                'ELEV',
               currentPoint?.?.toFixed(2) || 'Unknown',
                racePath?.distanceTotal?.toFixed(2) || 'Unknown'
                playbackFrame?.jump.toFixed(2) || '0',
                playbackTotals.executionElev.toFixed(2)
              )} */}
              {statEntryWithTitle(
                'SPEED',
                currentPoint?.speed?.toFixed(2) || '0',
                racePath?.speedMaximum?.toFixed(2) || '0'
              )}
            </Box>
          </Box>
          <Divider flexItem className={classes.statsDivider} style={{ height: 1 }} variant="fullWidth" />
          <Box
            className={classes.menuTitle}
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="auto"
          >
            <LocaleHeader setHudEnabled={props.setHudEnabled} />
            <Divider
              className={classes.statsDividerVert}
              orientation="vertical"
              style={{ height: 23 }}
              variant="fullWidth"
              flexItem
            />
            <CourseHeader setHudEnabled={props.setHudEnabled} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HudRaceStats;
