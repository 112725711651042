import 'reflect-metadata';
import { inject, injectable } from 'inversify';

import { getBiometrics } from 'api/axios';
import { Biometric } from 'interfaces/biometric.interface';
import { Logger } from './logger.service';
import StateProducer from 'features/state';

@injectable()
export default class BiometricService {
  @inject(Logger) public logger!: Logger;

  private _biometrics$ = new StateProducer([] as Biometric[]);
  public get biometrics$() {
    return this._biometrics$.consumer;
  }
  constructor() {}

  public async fetchAll(athleteId: string) {
    this.logger.info(`Fetch biometrics from server\n where athleteId="${athleteId}"`);

    const biometrics = await getBiometrics(athleteId);

    if (biometrics.length === 0) {
      this.logger.warn(`No biometrics were found\n where athleteId="${athleteId}.`);
    }

    this._setBiometrics(biometrics);
  }

  protected _setBiometrics(biometrics: Biometric[]) {
    this.logger.trace('Set biometrics', biometrics);
    this._biometrics$.value = biometrics;
  }

  public getBiometricByType(type: string): Biometric | null {
    this.logger.info('Get biometric data by type', type);

    return (
      this._biometrics$.value?.find(biometric => biometric.biometricType?.biometricTypeDescription === type) ?? null
    );
  }

  public getBiometricsByDate(date: Date): Biometric[] {
    this.logger.info('Get biometrics by data', date);

    return this._biometrics$.value?.filter(biometric => biometric.biometricDate === date) ?? [];
  }
}
