import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import StateProducer from 'features/state';
import { Nullable, Observer, Scene } from '@babylonjs/core';

import { PlaybackState } from 'features/hud/hudSlice';
import { RacePath } from 'interfaces/racePath.interface';
import RacePathService from './racePath.service';
import SceneService from './scene.service';
import StateObservable from 'features/state/stateObservable';
import { Logger } from './logger.service';
import { PointTime } from 'api/babylon/raceline/skierCostFunction';
import { RacePathPoint } from 'interfaces/racePathPoint.interface';
import { observables, Tower } from 'features/tower';

@injectable()
export default class PlayBackService {
  private _time$: StateProducer<{ current: number; max: number }> = new StateProducer({ current: 0, max: 0 });
  public get time$() {
    return this._time$.consumer;
  }
  private _playBackState$: StateProducer<PlaybackState> = new StateProducer(PlaybackState.Stop as PlaybackState);
  public get playBackState$() {
    return this._playBackState$.consumer;
  }
  public get playBackState() {
    return this._playBackState$.value;
  }
  private _currentPoint$: StateProducer<Nullable<RacePathPoint>> = new StateProducer(null as Nullable<RacePathPoint>);

  public get currentPoint$() {
    return this._currentPoint$.consumer;
  }

  private _points = null as Nullable<RacePathPoint[]>;
  private _currentPointIndex = null as Nullable<number>;

  private _playbackObserver: Nullable<Observer<PlaybackState>> = null;
  private _racePathObserver: Nullable<Observer<Nullable<RacePath>>> = null;
  private _onBeforeAnimationObserver: Nullable<Observer<Scene>> = null;

  private _tower: Tower;

  constructor(
    @inject(SceneService) private _sceneService: SceneService,
    @inject(RacePathService) private _racePathService: RacePathService,
    @inject(Logger) public logger: Logger
  ) {
    this._tower = Tower.builder(this._sceneService.scene$)
      .split(scene => {
        if (!scene) return;
        return [observables(this._racePathService.racePath$, this.playBackState$), { scene }];
      })
      .into(
        tower =>
          tower.finish(racepath => {
            this.stop();
            if (!racepath) {
              this._points = null;
              return;
            }

            this._points = racepath.racePathPoints ?? null;

            this._time$.value.max = racepath.timeSecTotal!;
            this._setTime(0);
          }),
        tower =>
          tower
            .then((playback, { scene }) => {
              switch (playback) {
                case PlaybackState.Play:
                  return [scene.onBeforeAnimationsObservable, { scene }];
                case PlaybackState.Stop:
                  this._setTime(0);
                  this._currentPointIndex = 0;
                  return;
                case PlaybackState.Pause:
                  return;
              }
            })
            .finish(scene => {
              const { current: ctime } = this._time$.value;

              const engine = scene.getEngine();
              const delta = engine.getDeltaTime() / 1000;

              if (!this._advanceTime(delta)) this.pause();
            })
      )
      .build();
  }

  public play() {
    this._playBackState$.value = PlaybackState.Play;
  }

  public pause() {
    this._playBackState$.value = PlaybackState.Pause;
  }

  public stop() {
    this._playBackState$.value = PlaybackState.Stop;
    this._currentPoint$.value = null;
  }

  public setTime(time: number) {
    this._setTime(time);
  }

  private _setTime(time: number): boolean {
    const { max } = this._time$.value;

    const { time: newTime, wasInBounds } = this._normalizeTime(time);

    if (this._points) {
      const { element: point, index } = this._findPointAtTime(time) ?? { element: null, index: null };

      this._currentPoint$.value = point;
      this._currentPointIndex = index;
    }

    this._time$.value = {
      current: newTime,
      max: max,
    };
    return wasInBounds;
  }

  private _advanceTime(delta: number) {
    const { current, max } = this._time$.value;
    const { time, wasInBounds } = this._normalizeTime(current + delta);

    if (this._points) {
      const pointResult = this._currentPointIndex ? this._findNextPoint(time) : this._findPointAtTime(time);
      const { element: point, index } = pointResult ?? { element: null, index: null };

      this._currentPoint$.value = point;
      this._currentPointIndex = index;
    }

    this._time$.value = {
      current: time,
      max: max,
    };
    return wasInBounds;
  }

  private _normalizeTime(time: number): { time: number; wasInBounds: boolean } {
    const { max } = this._time$.value;

    return 0 <= time && time <= max
      ? { time, wasInBounds: true }
      : {
          time: Math.min(max, Math.max(0, time)),
          wasInBounds: false,
        };
  }

  private _findPointAtTime(time: number) {
    if (!this._points) {
      this.logger.warn('Tried to find a racepoint at time ${time}, but the racepath is not calculated');
      return null;
    }
    return binarySearch.firstTrueInArray(this._points, element => element.time_sec >= time);
  }

  private _findNextPoint(time: number) {
    if (!this._points) {
      this.logger.warn(`Tried to find next race point at time ${time}, but the racepath is not calculated`);
      return null;
    }
    // Fallback
    if (!this._currentPointIndex) return this._findPointAtTime(time);

    for (let i = this._currentPointIndex; i < this._points.length; i++) {
      if (this._points[i].time_sec >= time) return { element: this._points[i], index: i };
    }
    return null;
  }
}

const binarySearch = {
  firstTrue(fromInclusive: number, toExclusive: number, predicate: (index: number) => boolean): Nullable<number> {
    let from = fromInclusive;
    let to = toExclusive;

    while (from !== to) {
      let midpoint = Math.floor((from + to) / 2);

      if (predicate(midpoint)) {
        to = midpoint;
      } else {
        from = midpoint + 1;
      }
    }

    if (from >= toExclusive) return null;
    return from;
  },

  firstTrueInArray<T>(
    array: T[],
    predicate: (element: T, index: number) => boolean
  ): Nullable<{ element: T; index: number }> {
    const index = this.firstTrue(0, array.length, index => predicate(array[index], index));
    if (index === null) return null;

    return {
      element: array[index],
      index,
    };
  },
};
