// icons
import { FilterHdr, Person } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/BarChartOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

// components
import Biometrics from '../pages/Biometrics';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';
import Terrain from '../pages/Terrain';
import Settings from '../pages/Settings';
import TestBabylon from '../pages/Test';

// interface
import RouteItem from '../model/RouteItem.model';

// define app routes
const routes: Array<RouteItem> = [
  {
    key: 'router-home',
    title: 'Home',
    icon: FilterHdr,
    tooltip: 'Home',
    path: '/',
    component: Home,
    isProtected: false,
  },
  {
    key: 'router-terrain',
    title: 'Terrain',
    tooltip: 'Terrain',
    path: '/terrain',
    redirectPath: '/',
    isProtected: true,
    component: Terrain,
    icon: FilterHdr,
  },
  {
    key: 'router-biometrics',
    title: 'Biometrics',
    tooltip: 'Biometrics',
    path: '/biometrics',
    redirectPath: '/',
    isProtected: true,
    component: Biometrics,
    icon: Person,
  },
  {
    key: 'router-dashboard',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    path: '/dashboard',
    redirectPath: '/',
    isProtected: true,
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    key: 'router-settings',
    title: 'Settings',
    tooltip: 'Settings',
    path: '/settings',
    redirectPath: '/',
    isProtected: true,
    component: Settings,
    icon: SettingsIcon,
  },
  {
    key: 'router-test',
    title: 'Test',
    tooltip: 'Test Babylon Functionality',
    path: '/test',
    redirectPath: '/',
    isProtected: false,
    component: TestBabylon,
    icon: SettingsIcon,
  },
];

export default routes;
