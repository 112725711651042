import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RacePath } from 'interfaces/racePath.interface';
import { RootState } from 'app/store';

export interface RacePathState {
  racePath: RacePath | null;
  racePaths: RacePath[];
  error: string;
}

const initialState: RacePathState = {
  racePath: null,
  racePaths: [],
  error: '',
};

export const racePathSlice = createSlice({
  name: 'paths',
  initialState,
  reducers: {
    calculateRacePath: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setRacePath: (state, action: PayloadAction<RacePath | null>) => {
      state.racePath = action.payload;
    },
    setRacePaths: (state, action: PayloadAction<RacePath[]>) => {
      state.racePaths = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { calculateRacePath, setRacePath, setRacePaths } = racePathSlice.actions;

export const selectRacePath = (state: RootState) => state.racePath.racePath;
export const selectRacePaths = (state: RootState) => state.racePath.racePaths;

export default racePathSlice.reducer;
