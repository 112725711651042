import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { SkiGate } from 'interfaces/skiGate.interface';

export interface SkiGateState {
  skiGates: SkiGate[];
  error: string;
}

const initialState: SkiGateState = {
  skiGates: [],
  error: '',
};

export const skiGateSlice = createSlice({
  name: 'gates',
  initialState,
  reducers: {
    setSkiGates: (state, action: PayloadAction<SkiGate[]>) => {
      state.skiGates = action.payload;
    },
    updateSkiGate: (state, action: PayloadAction<SkiGate>) => {
      state.skiGates = state.skiGates.map(obj => [action.payload].find(o => o.skiGateId === obj.skiGateId) || obj);
    },
  },
  extraReducers: builder => {},
});

export const { setSkiGates, updateSkiGate } = skiGateSlice.actions;

export const selectSkiGates = (state: RootState) => state.skiGate.skiGates;

export default skiGateSlice.reducer;
