import { ChangeEvent } from 'react';

import { Box, Divider, IconButton, Slider, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Camera, PauseRounded, PlayArrowRounded, StopRounded } from '@material-ui/icons';

import { useInjection, useObservable } from 'app/hooks';
import { PlaybackState } from './hudSlice';
import RacePathService from 'services/racePath.service';
import PlayBackService from 'services/playBack.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      bottom: '0%',
      backdropFilter: 'blur(5px)',
      marginBottom: 30,
      position: 'absolute',
      height: '100px',
      width: '100%',
    },
    menuButton: {
      display: 'block',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      marginLeft: 20,
      marginRight: 20,
      padding: 0,
    },
    menuContainer: {
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    menuContent: {
      marginBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      position: 'fixed',
      left: '50%',
      marginLeft: '-250px',
      width: '500px',
    },
    menuDivider: {
      background: theme.palette.primary.main,
      height: 3,
      opacity: 0.8,
    },
    playText: {
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginLeft: 10,
      marginRight: 10,
    },
    slider: {
      color: theme.palette.primary.main,
    },
    sliderRail: {
      height: 3,
    },
    sliderThumb: {},
    sliderTrack: {
      height: 3,
    },
  })
);

const HudPlayback = () => {
  const classes = useStyles();
  const theme = useTheme();
  const playBackService = useInjection(PlayBackService);
  const playBackState = useObservable(playBackService.playBackState$);
  const { current: currentTime, max: maxTime } = useObservable(playBackService.time$);

  // const racePathTime = useAppSelector(selectRacePathTime);

  const handleChange = (e: ChangeEvent<{}>, value: number | number[]) => {
    playBackService.setTime(value as number);
  };

  const handlePlayButton = () => {
    if (playBackState === PlaybackState.Pause || playBackState === PlaybackState.Stop) {
      playBackService.play();
    }
  };

  const handleStopButton = () => {
    if (playBackState === PlaybackState.Play || playBackState === PlaybackState.Pause) {
      playBackService.stop();
    }
  };

  const handlePauseButton = () => {
    if (playBackState === PlaybackState.Play) {
      playBackService.pause();
    }
  };

  return (
    <Toolbar className={classes.menu} disableGutters>
      <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center">
        <Divider className={classes.menuDivider} style={{ marginBottom: 80 }} variant="fullWidth" />
        <Box className={classes.menuContent} display="flex" flexDirection="column" justifyContent="center" width="auto">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="auto"
            style={{ marginTop: 30 }}
          >
            <Typography className={classes.playText} variant="subtitle2">
              {currentTime.toFixed(3)}
            </Typography>
            <Slider
              className={classes.slider}
              classes={{ rail: classes.sliderRail, thumb: classes.sliderThumb, track: classes.sliderTrack }}
              value={currentTime}
              step={0.001}
              max={maxTime}
              onChange={handleChange}
              aria-labelledby="continuous-slider"
            />
            <Typography className={classes.playText} variant="subtitle2">
              {maxTime.toFixed(3)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" width="auto" style={{ marginBottom: 20 }}>
            <IconButton className={classes.menuButton} onClick={handlePlayButton} disableRipple>
              <PlayArrowRounded htmlColor={theme.palette.primary.main} fontSize="small" />
            </IconButton>
            <IconButton className={classes.menuButton} onClick={handleStopButton} disableRipple>
              <StopRounded htmlColor={theme.palette.primary.main} fontSize="small" />
            </IconButton>
            <IconButton className={classes.menuButton} onClick={handlePauseButton} disableRipple>
              <PauseRounded htmlColor={theme.palette.primary.main} fontSize="small" />
            </IconButton>{' '}
          </Box>
        </Box>
        <Divider className={classes.menuDivider} variant="fullWidth" />
      </Box>
    </Toolbar>
  );
};

export default HudPlayback;
