import { useEffect } from 'react';

import CourseService from 'services/course.service';
import LocalesService from 'services/locale.service';
import { useObservable, useInjection } from 'app/hooks';

const CourseContainer = () => {
  const courseService = useInjection(CourseService);
  const localeService = useInjection(LocalesService);

  const locale = useObservable(localeService.currentLocale$);

  useEffect(() => {
    if (locale?.localeId) {
      courseService.fetchAll(locale.localeId);
    }
  }, [locale]);

  return null;
};

export default CourseContainer;
