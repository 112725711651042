import { PropsWithChildren } from 'react';
import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

import { useAppSelector } from '../../app/hooks';

const tntxBlue = '#002A4E';
const tntxYellow = '#FBDF00';

const CustomThemeProvider = (props: PropsWithChildren<{}>) => {
  const darkMode = useAppSelector((state) => state.darkMode);
  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: `${tntxBlue}`,
      },
      secondary: {
        main: `${tntxYellow}`,
      },
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default CustomThemeProvider;
