import { Nullable } from '@babylonjs/core';
import { createTaggedDecorator, inject, injectable, interfaces, METADATA_KEY } from 'inversify';

@injectable()
export default class PersistanceService {
  constructor() {}

  public builder(): PersistanceBuilder {
    return new PersistanceBuilder();
  }

  public scopedBuilder(...scope: string[]): PersistanceBuilder {
    return scope.reduce((builder, subScope) => builder.subScope(subScope), this.builder());
  }
}

export class PersistanceBuilder {
  public async store<T>(key: string, data: T) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public async retrive<T>(key: string): Promise<Nullable<T>> {
    const item = localStorage.getItem(key);
    if (item === null || item === undefined) return null;
    return JSON.parse(item);
  }

  public subScope(prefix: string): PersistanceScopedBuilder {
    return new PersistanceScopedBuilder(prefix);
  }
}

export class PersistanceScopedBuilder extends PersistanceBuilder {
  constructor(private _prefix: string) {
    super();
  }

  public async store<T>(key: string, data: T) {
    super.store(this._key(key), data);
  }

  public async retrive<T>(key: string) {
    return await super.retrive<T>(this._key(key));
  }

  public subScope(prefix: string) {
    return new PersistanceScopedBuilder(this._key(prefix));
  }

  private _key(key: string): string {
    return `${this._prefix}:${key}`;
  }
}

export const scoped = (...scope: string[]) => {
  return createTaggedDecorator({
    key: 'scope',
    value: scope,
  });
};

const isScopeTagWellFormed = (scope: unknown): scope is string[] => {
  return scope instanceof Array && (scope as unknown[]).every(scope => typeof scope === 'string');
};

export const providePersistanceBuilder = (context: interfaces.Context) => {
  const tags = context.currentRequest.target.getCustomTags();
  const scope = tags ? tags.find(({ key, value }) => key === 'scope')?.value : [];
  if (isScopeTagWellFormed(scope)) {
    const persistanceService = context.container.get(PersistanceService);
    console.log('scope', scope);
    return persistanceService.scopedBuilder(...scope);
  } else {
    throw new Error('Scope should be an array of strings');
  }
};
