import { Fragment } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { GameCanvas } from 'features/gameCanvas/GameCanvas';

import AthleteContainer from 'features/athlete/AthleteContainer';
import BiometricsContainer from 'features/biometrics/BiometricsContainer';
import BiometricsMenu from 'features/biometrics/BiometricsMenu';
import BiometricsToolbar from 'features/biometrics/BiometricsToolbar';

import { PAGE_TITLE_BIOMETRICS } from 'utils/constants';

const useStyles = makeStyles(() =>
  createStyles({
    pageRoot: {
      height: '100%',
      width: '100%',
      margin: 'none',
      overflow: 'hidden',
    },
  })
);

const Biometrics = () => {
  const classes = useStyles();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{PAGE_TITLE_BIOMETRICS}</title>
      </Helmet>
      <Fragment>
        <div className={classes.pageRoot}>
          <AthleteContainer />
          <BiometricsContainer />
          <BiometricsToolbar />
          <GameCanvas sceneName={'avatarScene'} width={'50%'} margin={'50%'}>
            <BiometricsMenu />
          </GameCanvas>
        </div>
      </Fragment>
    </HelmetProvider>
  );
};

export default Biometrics;
