import { Fragment, MouseEvent, PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { useInjection, useObservable } from '../../app/hooks';
import CourseService from 'services/course.service';

import { IHudControl } from 'features/hud/HudControls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      height: '45px',
      width: '45px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuText: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      justifyContent: 'flex-start',
    },
  })
);

const CourseHeader = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();

  const courseService = useInjection(CourseService);
  const current = useObservable(courseService.currentCourse$);

  const handleToggleHud = (e: MouseEvent<HTMLElement>) => {
    props.setHudEnabled(false);
  };

  return (
    <Fragment>
      <Button className={classes.menuButton} onClick={handleToggleHud} disableRipple>
        <Typography className={classes.menuText} variant="h5">
          {current ? current.courseCode : ''}
        </Typography>
      </Button>
    </Fragment>
  );
};

export default CourseHeader;
