import { useEffect } from 'react';

import AnnotationsService from 'services/annotation.service';
import TerrainsService from 'services/terrain.service';
import { useInjection, useObservable } from 'app/hooks';

const AnnotationsContainer = () => {
  const annotationsService = useInjection(AnnotationsService);
  const terrainService = useInjection(TerrainsService);

  const annotations = useObservable(annotationsService.annotations$);
  const terrain = useObservable(terrainService.currentTerrain$);

  // valid gate entries
  useEffect(() => {}, [annotations]);

  useEffect(() => {
    if (terrain?.terrainId) {
      annotationsService.fetch(terrain.terrainId);
    }
  }, [terrain]);

  return null;
};

export default AnnotationsContainer;
