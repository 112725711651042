import React from 'react';
import ReactDOM from 'react-dom';

import ReduxProvider from './features/persist/ReduxProvider';
import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
