import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Paper from '@material-ui/core/Paper';

import CustomThemeProvider from 'features/theme/CustomThemeProvider';
import DefaultComponent from 'components/Default';
import IOCContainer from 'features/ioc/IOCContainer';
import IOCProvider from 'features/ioc/IOCProvider';
import Layout from 'components/Layout';
import RouteItem from 'model/RouteItem.model';
import ProtectedRoute from 'features/authentication/ProtectedRoute';
import routes from 'app/routes';

import { APP_TITLE } from 'utils/constants';

function App() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{APP_TITLE}</title>
        </Helmet>
        <CustomThemeProvider>
          <IOCProvider container={IOCContainer}>
            <Router>
              <Paper style={{ boxShadow: 'none' }}>
                <Layout>
                  <Switch>
                    {routes.map((route: RouteItem) =>
                      route.isProtected ? (
                        <ProtectedRoute
                          key={`${route.key}`}
                          path={`${route.path}`}
                          component={route.component || DefaultComponent}
                          redirectPath={route.redirectPath || '/'}
                          exact
                        />
                      ) : (
                        <Route
                          key={`${route.key}`}
                          path={`${route.path}`}
                          component={route.component || DefaultComponent}
                          exact
                        />
                      )
                    )}
                  </Switch>
                </Layout>
              </Paper>
            </Router>
          </IOCProvider>
        </CustomThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
