import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const get = async <T>(path: string): Promise<T> => {
  const { data } = await axios.get<T>(`${path}`);
  return data;
};

export const update = async <T>(path: string, data: T | T[]) => {
  if (Array.isArray(data)) {
    axios
      .all(
        data.map(d => {
          return axios.put<T>(`${path}`, d);
        })
      )
      .catch(error => {
        console.log(`Error: ${error}`);
      });
  }
  return axios.put<T>(`${path}`, data);
};
