import { PropsWithChildren, useReducer } from 'react';
import { Link } from 'react-router-dom';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Divider, IconButton, SvgIcon, Toolbar } from '@material-ui/core';
import { Accessibility, Flag, LocationOn } from '@material-ui/icons';

import UploadRacePathDialog from 'features/paths/UploadRacePathDialog';
import { enableXrMode, selectXrMode } from 'features/xrMode/xrModeSlice';

import { useAppSelector, useAppDispatch, useInjection } from 'app/hooks';

import { ReactComponent as VrIcon } from 'assets/images/vricon.svg';
import { ReactComponent as SpeedIcon } from 'assets/images/speedicon.svg';
import RacePathService from 'services/racePath.service';
import SkiGatesService from 'services/skiGates.service';

import { IHudControl } from 'features/hud/HudControls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      bottom: '0%',
      backdropFilter: 'blur(5px)',
      marginBottom: 30,
      position: 'absolute',
      height: '100px',
      width: '100%',
    },
    menuButton: {
      display: 'block',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      width: '80px',
    },
    menuContainer: {
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    menuContent: {
      marginBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      position: 'fixed',
      left: '50%',
      marginLeft: '-150px',
      width: '300px',
    },
    menuDivider: {
      background: theme.palette.primary.main,
      height: 3,
      opacity: 0.8,
    },
    menuIcon: {
      color: theme.palette.primary.main,
    },
  })
);

const TerrainMenu = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();
  const [openRacePathDialog, toggleOpenDialog] = useReducer(openRacePathDialog => !openRacePathDialog, false);

  const theme = useTheme();

  const xrMode = useAppSelector(selectXrMode);
  const dispatch = useAppDispatch();

  const racePathService = useInjection(RacePathService);
  const skiGateService = useInjection(SkiGatesService);

  const handleGatesUpdate = () => {
    if (window.confirm('Do you want to push the gates positions to the server(this action is not reversable)?'))
      skiGateService.updateDB();
  };

  const handleRecalculatePath = () => {
    racePathService.recalculate();
    props.setHudEnabled(true);
  };

  const handleXrModeToggle = () => {
    dispatch(enableXrMode(!xrMode));
  };

  return (
    <Toolbar className={classes.menu} disableGutters>
      <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center">
        <Divider className={classes.menuDivider} style={{ marginBottom: 80 }} variant="fullWidth" />
        <Box className={classes.menuContent} display="flex" flexDirection="row" justifyContent="center" width="auto">
          <IconButton className={classes.menuButton} onClick={handleRecalculatePath} disableRipple>
            <SvgIcon htmlColor={theme.palette.primary.main}>
              <SpeedIcon />
            </SvgIcon>
          </IconButton>
          <IconButton className={classes.menuButton} onClick={toggleOpenDialog} disableRipple>
            <LocationOn htmlColor={theme.palette.primary.main} />
            <UploadRacePathDialog open={openRacePathDialog} handleCloseDialog={toggleOpenDialog} />
          </IconButton>
          <IconButton className={classes.menuButton} onClick={handleGatesUpdate} disableRipple>
            <Flag htmlColor={theme.palette.primary.main} />
          </IconButton>
          <IconButton className={classes.menuButton} component={Link} to="/biometrics" disableRipple>
            <Accessibility htmlColor={theme.palette.primary.main} />
          </IconButton>
          <IconButton className={classes.menuButton} onClick={handleXrModeToggle} disableRipple>
            <SvgIcon htmlColor={theme.palette.primary.main}>
              <VrIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Divider className={classes.menuDivider} variant="fullWidth" />
      </Box>
    </Toolbar>
  );
};

export default TerrainMenu;
