import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export enum PlaybackState {
  Play = 0,
  Pause = 1,
  Stop = 2,
}

export interface RacePathTime {
  time: number;
  dist: number;
  elev: number;
  speed: number;
}

export interface HudState {
  enable: boolean;
  currentTime: number;
  playbackState: PlaybackState;
  racePathTime: RacePathTime;
  racePathTotals: RacePathTime;
}

const initialState: HudState = {
  enable: false,
  currentTime: 0,
  playbackState: PlaybackState.Stop,
  racePathTime: {
    time: 0,
    dist: 0,
    elev: 0,
    speed: 0,
  },
  racePathTotals: {
    time: 0,
    dist: 0,
    elev: 0,
    speed: 0,
  },
};

const hudSlice = createSlice({
  name: 'hud',
  initialState,
  reducers: {
    toggleHud: state => {
      state.enable = !state.enable;
    },
    setCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
    updateCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime =
        state.currentTime + action.payload > state.racePathTotals.time
          ? state.currentTime + action.payload - state.racePathTotals.time
          : state.currentTime + action.payload;
    },
    setPlaybackState: (state, action: PayloadAction<number>) => {
      state.playbackState = action.payload;
    },
    setRacePathTime: (state, action: PayloadAction<RacePathTime>) => {
      state.racePathTime = action.payload;
    },
    setRacePathTotals: (state, action: PayloadAction<RacePathTime>) => {
      state.racePathTotals = action.payload;
    },
  },
  extraReducers: {},
});

export const { setCurrentTime, setPlaybackState, toggleHud } = hudSlice.actions;

export const selectHudEnabled = (state: RootState) => state.hud.enable;
export const selectCurrentTime = (state: RootState) => state.hud.currentTime;
export const selectPlaybackState = (state: RootState) => state.hud.playbackState;
export const selectRacePathTime = (state: RootState) => state.hud.racePathTime;
export const selectRacePathTotals = (state: RootState) => state.hud.racePathTotals;

export default hudSlice.reducer;
