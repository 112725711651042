import CourseContainer from 'features/course/CourseContainer';
import { Container } from 'inversify';

import AnnotationsService from 'services/annotation.service';
import AthleteService from 'services/athlete.service';
import BiometricService from 'services/biometric.service';
import CameraService from 'services/camera.service';
import CourseService from 'services/course.service';
import LocaleService, { LocaleServiceWithPersistance } from 'services/locale.service';
import LoggerService, { ConsoleLoggerService, Logger, provideLogger } from 'services/logger.service';
import PersistanceService, { PersistanceBuilder, providePersistanceBuilder } from 'services/persistance.service';
import PlayBackService from 'services/playBack.service';
import RacePathService from 'services/racePath.service';
import SceneService from 'services/scene.service';
import SkiGatesService from 'services/skiGates.service';
import TerrainsService from 'services/terrain.service';
import { IOCContainerBuilder } from './IOCContainerBuilder';

const createContainer = (): Container => {
  const container = new Container({ defaultScope: 'Singleton' });
  container.unbindAll();

  const builder = new IOCContainerBuilder(container)
    .bind(Container, bind => bind.toConstantValue(container))
    // Logging
    .bind(LoggerService, bind => bind.to(ConsoleLoggerService))
    .bind(Logger, bind => bind.toDynamicValue(provideLogger).inTransientScope())
    // Persistance
    .bind(PersistanceService)
    .bind(PersistanceBuilder, bind => bind.toDynamicValue(providePersistanceBuilder).inRequestScope())
    // Babylon facing services
    .bind(SceneService)
    .bind(CameraService)
    // General services
    .bind(LocaleService)
    .bind(CourseService)
    .bind(TerrainsService)
    .bind(SkiGatesService)
    .bind(RacePathService)
    .bind(AthleteService)
    .bind(BiometricService)
    .bind(AnnotationsService)
    .bind(PlayBackService);

  return container;
};

export default createContainer();
