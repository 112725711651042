import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Locale } from 'interfaces/locale.interface';
import { RootState } from 'app/store';

export interface LocaleState {
  locale: Locale | null;
  locales: Locale[];
}

const initialState: LocaleState = {
  locale: null,
  locales: [],
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
    setLocales: (state, action: PayloadAction<Locale[]>) => {
      state.locales = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setLocale, setLocales } = localeSlice.actions;

export const selectLocale = (state: RootState) => state.locale.locale;
export const selectLocales = (state: RootState) => state.locale.locales;

export default localeSlice.reducer;
