import { Fragment } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { GameCanvas } from 'features/gameCanvas/GameCanvas';
import CourseContainer from 'features/course/CourseContainer';
import GatesContainer from 'features/gates/GatesContainer';
import LocaleContainer from 'features/locale/LocaleContainer';
import TerrainContainer from 'features/terrain/TerrainContainer';
import PathsContainer from 'features/paths/PathsContainer';
import { ReflectorSideBar } from 'features/reflector/ReflectorSideBar';

// define css-in-js
const useStyles = makeStyles(() =>
  createStyles({
    pageRoot: {
      height: '100%',
      width: '100%',
      margin: 'none',
      overflow: 'hidden',
    },
  })
);

const TestBabylon = () => {
  const classes = useStyles();

  const getUrlSceneParam = (): string => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('test-scene')?.replace(/['"]+/g, '') || '';
  };

  return (
    <Fragment>
      <div className={classes.pageRoot}>
        <CourseContainer />
        <GatesContainer />
        <LocaleContainer />
        <PathsContainer />
        <TerrainContainer />
        <ReflectorSideBar></ReflectorSideBar>
        <GameCanvas sceneName={getUrlSceneParam()} />
      </div>
    </Fragment>
  );
};

export default TestBabylon;
