import { Fragment } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { useObservable, useInjection } from 'app/hooks';
import CourseService from 'services/course.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      height: '45px',
      width: '45px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuText: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      justifyContent: 'flex-start',
    },
  })
);

const CourseDropdown = () => {
  const classes = useStyles();
  // const current = useAppSelector(selectCourse);
  // const dispatch = useAppDispatch();

  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const [openMenu, setOpenMenu] = useState(false);

  const courseService = useInjection(CourseService);
  const current = useObservable(courseService.currentCourse$);

  return (
    <Fragment>
      <Button className={classes.menuButton} disableRipple>
        <Typography className={classes.menuText} variant="h5">
          {current ? current.courseCode : 'Select Location'}
        </Typography>
      </Button>
    </Fragment>
  );
};

export default CourseDropdown;
