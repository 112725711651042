import { Course } from 'interfaces/course.interface';
import { get } from './request.axios';

const apiPath = `/course`;
const apiSubPath = `/locale`;

export const getCourse = async (courseId: string) => {
  return get<{ data: Course }>(`${apiPath}/${courseId}`).then(response => {
    return response.data;
  });
};

export const getCourses = async (localeId: string) => {
  return get<{ data: Course[] }>(`${apiSubPath}/${localeId}/course`).then(response => {
    return response.data;
  });
};
