import { Container, interfaces } from 'inversify';

export class IOCContainerBuilder {
  private _identifiers: Array<interfaces.ServiceIdentifier<unknown>> = [];

  constructor(public container: Container) {}
  public bind<T>(
    identifier: interfaces.ServiceIdentifier<T>,
    binder: (bind: interfaces.BindingToSyntax<T>) => void = bind => bind.toSelf()
  ) {
    binder(this.container.bind(identifier));
    this._identifiers.push(identifier);
    return this;
  }

  public build(all: boolean = true) {
    for (const id of this._identifiers) {
      if (all) this.container.getAll(id);
      else this.container.get(id);
    }
  }
}
