import { Biometric } from 'interfaces/biometric.interface';
import { get } from './request.axios';

const apiPath = `/biometrics`;
const apiSubPath = `/athlete`;

export const getBiometric = async (biometricId: string) => {
  return get<{ data: Biometric }>(`${apiPath}/${biometricId}`).then(response => {
    return response.data;
  });
};

export const getBiometrics = async (athleteId: string) => {
  return get<{ data: Biometric[] }>(`${apiSubPath}/${athleteId}/biometrics`).then(response => {
    return response.data;
  });
};
