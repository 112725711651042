import 'reflect-metadata';
import { inject, injectable } from 'inversify';

import { Nullable } from '@babylonjs/core';

import StateProducer from 'features/state';
import { getTerrainVersions } from 'api/axios';
import { Terrain } from 'interfaces/terrain.interface';
import { Logger } from './logger.service';

@injectable()
export default class TerrainService {
  @inject(Logger) public logger!: Logger;

  private _terrains$ = new StateProducer([] as Terrain[]);
  public get terrains$() {
    return this._terrains$.consumer;
  }
  private _currentTerrain$ = new StateProducer(null as Nullable<Terrain>);
  public get currentTerrain$() {
    return this._currentTerrain$.consumer;
  }

  constructor() {}

  public async fetchAll(courseId: string) {
    this.logger.info(`Fetch terrains from server\n where courseId="${courseId}"`);

    const terrains = await getTerrainVersions(courseId);

    if (terrains.length === 0) {
      this.logger.warn(`No terrains were found\n where courseId="${courseId}.`);
    }

    this._setTerrains(terrains);
  }

  protected _setTerrain(terrain: Terrain | null) {
    this.logger.trace('Set current terrain', terrain);

    if ((this._currentTerrain$.value = terrain)) {
      return;
    }
    this._currentTerrain$.value = terrain;
  }

  protected _setTerrains(terrains: Terrain[]) {
    this.logger.trace('Set terrains', terrains);

    if (
      !this._currentTerrain$.value ||
      terrains.find(l => l.terrainId === this._currentTerrain$.value?.terrainId) === undefined
    ) {
      const current = terrains.find(terrain => terrain.isPublished === true) ?? null;
      this._setTerrain(current);
    }
    this._terrains$.value = terrains;
  }

  public setTerrainByVersion(version: number) {
    this.logger.info('Set terrain by version', version);

    this._setTerrainBySelector(terrain => terrain.version === version);
  }

  public setTerrainById(terrainId: string) {
    this.logger.info('Set terrain by id', terrainId);

    this._setTerrainBySelector(terrain => terrain.terrainId === terrainId);
  }

  private _setTerrainBySelector(selector: (terrain: Terrain) => boolean) {
    const terrains = this._terrains$.value;
    if (!terrains) {
      this.logger.warn('Current terrain set before terrains were fetched');
      return;
    }

    const current = terrains.find(selector) ?? null;
    if (!current) {
      this.logger.warn('No such terrain found, current terrain will be unset');
    }

    this._setTerrain(current);
  }
}
