import { EventState, Nullable, Observable, Observer } from '@babylonjs/core';
import { useEffect, useState } from 'react';

import StateObservable from './stateObservable';

export default class StateProducer<T> {
  private _observable: StateObservable<T>;
  private _consumer: StateConsumer<T>;

  constructor(
    initial: T,
    equals: (lhs: T, rhs: T) => boolean = (lhs: T, rhs: T) => lhs === rhs,
    clone: (state: T) => T = state => state
  ) {
    this._observable = new StateObservable(initial, equals, clone);
    this._consumer = new StateConsumer(this._observable);
  }

  public set value(value: T) {
    this._observable.notifyObservers(value);
  }

  public get value(): T {
    return this._observable._getCurrent();
  }

  public forceNotifyObservers() {
    this._observable.forceNotifyObserversWithValue();
  }

  public get consumer() {
    return this._consumer;
  }
}

export class StateConsumer<T> {
  constructor(private _observable: StateObservable<T>) {}

  public add(callback: (eventData: T, eventState: EventState) => void, insertFirst?: boolean): Nullable<Observer<T>> {
    return this._observable.add(callback, undefined, insertFirst);
  }

  public addOnce(callback: (eventData: T, eventState: EventState) => void): Nullable<Observer<T>> {
    return this._observable.addOnce(callback);
  }

  public remove(observer: Nullable<Observer<T>>) {
    return this._observable.remove(observer);
  }

  public clear() {
    this._observable.clear();
  }
}

const getRawObservableWithCheck = () => {
  const getObservable = <T>(observable: StateConsumer<T>) => {
    // This code needs to be changed whenever the definition of the StateConsumer changes
    return (observable as any as { _observable: StateObservable<T> })._observable;
  };

  const duckTypeCheckConsumer = () => {
    const producer = new StateProducer(null);
    const consumer = producer.consumer;
    const data = consumer as any;

    const observable = getObservable(data);

    if (!observable || observable.constructor !== StateObservable) {
      throw new TypeError(
        'getRawObservable implementation is incorrect, perhaps you changed the definition of StateConsumer?'
      );
    }
  };

  duckTypeCheckConsumer();
  return getObservable;
};

/**
 * Don't use it unless you *really* need to.
 *
 * Circumvents typescript typing because unfortunately there is nothing like internal visibility modifier
 * @returns raw observable which can be used to get or set the value
 */
export const getRawObservable = getRawObservableWithCheck();
