import { Nullable, Observable, Observer } from '@babylonjs/core';

type TowerUpper<TNext, TNextCapture> =
  | { type: 'TOP'; value: TowerTop<TNext, TNextCapture> }
  | {
      type: 'MIDDLE';
      value: TowerMiddle<any, TNext, any, TNextCapture>;
    };
const TowerUpper = {
  fromTop: <TNext, TNextCapture>(top: TowerTop<TNext, TNextCapture>) =>
    ({
      type: 'TOP',
      value: top,
    } as TowerUpper<TNext, TNextCapture>),
  fromMiddle: <TNext, TNextCapture>(middle: TowerMiddle<any, TNext, any, TNextCapture>) =>
    ({
      type: 'MIDDLE',
      value: middle,
    } as TowerUpper<TNext, TNextCapture>),
};

type TowerLower<TPrev, TPrevCapture> =
  | { type: 'BOTTOM'; value: TowerBottom<TPrev, TPrevCapture, any> }
  | {
      type: 'SPLIT';
      value: TowerSplit<TPrev, any[], TPrevCapture, any>;
    }
  | {
      type: 'MIDDLE';
      value: TowerMiddle<TPrev, any, TPrevCapture, any>;
    };
const TowerLower = {
  fromBottom: <TPrev, TPrevCapture>(bottom: TowerBottom<TPrev, TPrevCapture>) =>
    ({
      type: 'BOTTOM',
      value: bottom,
    } as TowerLower<TPrev, TPrevCapture>),
  fromMiddle: <TPrev, TPrevCapture>(middle: TowerMiddle<TPrev, any, TPrevCapture, any>) =>
    ({
      type: 'MIDDLE',
      value: middle,
    } as TowerLower<TPrev, TPrevCapture>),

  fromSplit: <TPrev, TPrevCapture>(split: TowerSplit<TPrev, any[], TPrevCapture, any>) =>
    ({
      type: 'SPLIT',
      value: split,
    } as TowerLower<TPrev, TPrevCapture>),
};

type PromiseOrValue<T> = T | PromiseLike<T>;

interface ObservableLike<T> {
  add(callback: (value: T) => PromiseOrValue<void>): Nullable<Observer<T>>;
  remove(observer: Nullable<Observer<T>>): void;
}

type MiddleCallback<TPrev, TCurrent, TPrevCapture, TCurrentCapture> = (
  value: TPrev,
  capture: TPrevCapture
) => PromiseOrValue<[ObservableLike<TCurrent>, TCurrentCapture] | undefined>;

class TowerTop<TCurrent, TCurrentCapture = undefined> {
  private _child: Nullable<TowerLower<TCurrent, TCurrentCapture>> = null;

  constructor(public observable: Nullable<ObservableLike<TCurrent>>) {}

  public then<TNext, TNextCapture>(
    callback: MiddleCallback<TCurrent, TNext, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerMiddle<TCurrent, TNext, TCurrentCapture, TNextCapture> {
    const middle = new TowerMiddle(TowerUpper.fromTop(this), callback, cleanUp);
    this.setChild(TowerLower.fromMiddle(middle));

    return middle;
  }

  public split<TSub extends [...any[]], TNextCapture>(
    callback: SplitCallback<TCurrent, TSub, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerSplitHelper<TCurrent, TSub, TCurrentCapture, TNextCapture> {
    return new TowerSplitHelper(TowerUpper.fromTop(this), callback, cleanUp);
  }

  public finish<TNextCapture>(
    callback: BottomCallback<TCurrent, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerBottom<TCurrent, TCurrentCapture, TNextCapture> {
    const bottom = new TowerBottom(TowerUpper.fromTop(this), callback, cleanUp);
    this.setChild(TowerLower.fromBottom(bottom));

    return bottom;
  }

  public dispose(): void {
    if (this._child) this._child.value.dispose();
  }

  public run(capture: TCurrentCapture) {
    if (this._child === null) {
      throw new Error("Tower top's run function called before then method or build method were called");
    }
    if (this.observable === null) {
      throw new Error("Look's like tower top's observable isn't set, this is probably an implementation error.");
    }
    this._child.value.run(this.observable, capture);
  }

  public setChild(child: TowerLower<TCurrent, TCurrentCapture>) {
    if (this._child) {
      throw new Error("Tower top's child set twice");
    }
    this._child = child;
  }
}

class TowerMiddle<TPrev, TCurrent, TPrevCapture, TCurrentCapture> {
  private _previousObservable: Nullable<ObservableLike<TPrev>> = null;
  private _currentCapture: Nullable<TCurrentCapture> = null;

  private _observer: Nullable<Observer<TPrev>> = null;
  private _child: Nullable<TowerLower<TCurrent, TCurrentCapture>> = null;

  constructor(
    public parent: TowerUpper<TPrev, TPrevCapture>,
    private _callback: MiddleCallback<TPrev, TCurrent, TPrevCapture, TCurrentCapture>,
    private _cleanUp: (capture: TCurrentCapture) => void
  ) {}

  public then<TNext, TNextCapture>(
    callback: MiddleCallback<TCurrent, TNext, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerMiddle<TCurrent, TNext, TCurrentCapture, TNextCapture> {
    const middle = new TowerMiddle(TowerUpper.fromMiddle(this), callback, cleanUp);
    this.setChild(TowerLower.fromMiddle(middle));

    return middle;
  }

  public split<TSub extends [...any[]], TNextCapture>(
    callback: SplitCallback<TCurrent, TSub, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerSplitHelper<TCurrent, TSub, TCurrentCapture, TNextCapture> {
    return new TowerSplitHelper(TowerUpper.fromMiddle(this), callback, cleanUp);
  }

  public finish<TNextCapture>(
    callback: BottomCallback<TCurrent, TCurrentCapture, TNextCapture>,
    cleanUp: (capture: TNextCapture) => void = () => {}
  ): TowerBottom<TCurrent, TCurrentCapture, TNextCapture> {
    const bottom = new TowerBottom(TowerUpper.fromMiddle(this), callback, cleanUp);
    this.setChild(TowerLower.fromBottom(bottom));

    return bottom;
  }

  public run(observable: ObservableLike<TPrev>, capture: TPrevCapture) {
    this.dispose();

    if (this._child === null) {
      throw new Error("Tower middle's run function called before then method or build method were called");
    }

    this._observer = observable.add(async value => {
      this._clean();

      const result = await this._callback(value, capture);
      if (result) {
        const [currentObservable, currentCapture] = result;
        this._child!.value.run(currentObservable, currentCapture);
        this._currentCapture = currentCapture;
      } else this._child!.value.dispose();
    });
    this._previousObservable = observable;
  }

  public dispose(): void {
    this._clean();
    if (this._child) this._child.value.dispose();
    if (this._previousObservable) this._previousObservable.remove(this._observer);
  }

  public setChild(child: TowerLower<TCurrent, TCurrentCapture>) {
    if (this._child) {
      throw new Error("Tower middle's child set twice");
    }
    this._child = child;
  }

  private _clean() {
    if (this._currentCapture !== null) this._cleanUp(this._currentCapture);
    this._currentCapture = null;
  }
}

type BottomCallback<TPrev, TPrevCapture, TCurrentCapture> = (
  value: TPrev,
  capture: TPrevCapture
) => PromiseOrValue<TCurrentCapture>;

class TowerBottom<TPrev, TPrevCapture, TCurrentCapture> {
  private _observer: Nullable<Observer<TPrev>> = null;
  private _previousObservable: Nullable<ObservableLike<TPrev>> = null;
  private _currentCapture: Nullable<TCurrentCapture> = null;

  constructor(
    private _parent: TowerUpper<TPrev, TPrevCapture>,
    private _callback: BottomCallback<TPrev, TPrevCapture, TCurrentCapture>,
    private _cleanUp: (capture: TCurrentCapture) => void
  ) {}

  public run(observable: ObservableLike<TPrev>, capture: TPrevCapture) {
    this.dispose();

    this._observer = observable.add(async value => {
      this._clean();
      this._currentCapture = await this._callback(value, capture);
    });
    this._previousObservable = observable;
  }

  public build(): Tower {
    // any is fine here since we don't use any of the fields other than parent
    let towerNode: TowerUpper<any, any> = this._parent;
    while (towerNode.type === 'MIDDLE') {
      towerNode = towerNode.value.parent;
    }

    // The only way to possible build TopTower with capture is to do it in a split which is not allowed
    const top = towerNode.value as TowerTop<unknown>;
    top.run(undefined);
    return Tower._fromTop(top);
  }

  public dispose(): void {
    this._clean();
    if (this._previousObservable) this._previousObservable.remove(this._observer);
  }

  private _clean() {
    if (this._currentCapture !== null) this._cleanUp(this._currentCapture);
    this._currentCapture = null;
  }
}

// Helpers for Tower split

type Observables<Tuple extends [...any[]]> = {
  [Index in keyof Tuple]: ObservableLike<Tuple[Index]>;
};

type Tops<Tuple extends [...any[]], TCapture> = {
  [Index in keyof Tuple]: TowerTop<Tuple[Index], TCapture>;
};

type SplitCallback<TPrev, TSub extends [...any[]], TPrevCapture, TCurrentCapture> = (
  value: TPrev,
  capture: TPrevCapture
) => PromiseOrValue<[Observables<TSub>, TCurrentCapture] | undefined>;

type SubTowerConstructors<Tuple extends [...any[]], TCapture> = {
  [Index in keyof Tuple]: (top: TowerTop<Tuple[Index], TCapture>) => TowerBottom<any, any, any>;
};

class TowerSplitHelper<TPrev, TSub extends [...any[]], TPrevCapture, TCurrentCapture> {
  constructor(
    private _parent: TowerUpper<TPrev, TPrevCapture>,
    private _callback: SplitCallback<TPrev, TSub, TPrevCapture, TCurrentCapture>,
    private _cleanUp: (capture: TCurrentCapture) => void
  ) {}

  public into(
    ...subTowersConstructors: SubTowerConstructors<TSub, TCurrentCapture>
  ): TowerSplit<TPrev, TSub, TPrevCapture, TCurrentCapture> {
    const split = new TowerSplit(this._parent, this._callback, this._cleanUp, subTowersConstructors);
    this._parent.value.setChild(TowerLower.fromSplit(split));
    return split;
  }
}

class TowerSplit<TPrev, TSub extends [...any[]], TPrevCapture, TCurrentCapture> {
  private _children: Tops<TSub, TCurrentCapture>;

  private _observer: Nullable<Observer<TPrev>> = null;
  private _previousObservable: Nullable<ObservableLike<TPrev>> = null;
  private _currentCapture: Nullable<TCurrentCapture> = null;

  constructor(
    private _parent: TowerUpper<TPrev, TPrevCapture>,
    private _callback: SplitCallback<TPrev, TSub, TPrevCapture, TCurrentCapture>,
    private _cleanUp: (capture: TCurrentCapture) => void,
    subTowersConstructors: SubTowerConstructors<TSub, TCurrentCapture>
  ) {
    const tops = Array(subTowersConstructors.length)
      .fill(0)
      .map(() => new TowerTop(null)) as Tops<TSub, TCurrentCapture>;

    tops.forEach((top, i) => subTowersConstructors[i](top));

    this._children = tops;
  }

  public run(observable: ObservableLike<TPrev>, capture: TPrevCapture) {
    this.dispose();

    this._observer = observable.add(async value => {
      this._clean();

      const result = await this._callback(value, capture);

      if (!result) return;

      const [observables, currentCapture] = result;

      observables.forEach((currentObservable, i) => {
        const top = this._children[i];
        top.dispose();
        top.observable = currentObservable;
        top.run(currentCapture);
      });

      this._currentCapture = currentCapture;
    });
    this._previousObservable = observable;
  }

  public build(): Tower {
    let towerNode: TowerUpper<any, any> = this._parent;
    while (towerNode.type === 'MIDDLE') {
      towerNode = towerNode.value.parent;
    }

    const top = towerNode.value as TowerTop<unknown, undefined>;
    top.run(undefined);
    return Tower._fromTop(top);
  }

  public dispose() {
    this._children.forEach(top => top.dispose());
    this._clean();
    if (this._previousObservable) this._previousObservable.remove(this._observer);
  }

  private _clean() {
    if (this._currentCapture !== null) this._cleanUp(this._currentCapture);
    this._currentCapture = null;
  }
}

export class Tower {
  private constructor(private _top: TowerTop<any, undefined>) {}

  public static builder<T>(observable: ObservableLike<T>) {
    return new TowerTop(observable);
  }

  /** Don't use it yourself unless you know what you're doing, it is only public because it is used by unrelated classes in the module and the workarounds are worse then leaving it public. */
  public static _fromTop(top: TowerTop<any, undefined>) {
    return new Tower(top);
  }

  public dispose() {
    this._top.dispose();
  }
}

// utility to assist type inference without spelling the full type
export const observables = <TSub extends [...any[]]>(...observables: Observables<TSub>) =>
  observables as Observables<TSub>;
