import { ChangeEvent, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tab, Tabs, Toolbar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Menu, Search } from '@material-ui/icons';

import logo from 'assets/images/cortinalogo.png';

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      background: 'linear-gradient(to right bottom, #363636, #212121)',
      borderBottom: '2px solid #616161',
    },
    fisIcon: {
      position: 'absolute',
      top: '5px',
      left: '95px',
    },
    fisLogo: {
      height: '3em',
    },
    menuIcon: {
      position: 'absolute',
      left: '10px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    searchIcon: {
      position: 'absolute',
      right: '10px',
    },
    tab: {
      minWidth: '50px',
      height: '60px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    tabContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    tabIndicator: {
      backgroundColor: 'white',
    },
  })
);

interface ITabOption {
  name: string;
  route?: string;
}

const BiometricToolbar = () => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleIndexChange = (e: ChangeEvent<{}>, value: number) => {
    setTabIndex(value);
  };

  const tabOptions: ITabOption[] = [{ name: 'Races' }, { name: 'Stats' }, { name: 'Training' }, { name: 'Sponsors' }];

  return (
    <Fragment>
      <Toolbar className={classes.toolbar} disableGutters>
        <IconButton className={classes.menuIcon} component={Link} to="/terrain" disableRipple>
          <Menu />
        </IconButton>
        <IconButton className={classes.fisIcon}>
          <img alt="company logo" src={logo} className={classes.fisLogo} />
        </IconButton>
        <Tabs
          value={tabIndex}
          onChange={handleIndexChange}
          className={classes.tabContainer}
          classes={{
            indicator: classes.tabIndicator,
          }}
        >
          {tabOptions.map((option, i) => (
            <Tab key={`bioStatTab${i}`} className={classes.tab} color="secondary" label={option.name} disableRipple />
          ))}
        </Tabs>
        <IconButton className={classes.searchIcon}>
          <Search />
        </IconButton>
      </Toolbar>
    </Fragment>
  );
};

export default BiometricToolbar;
