import { Container } from 'inversify';
import { Engine, Scene } from '@babylonjs/core';

import { IOCContainerBuilder } from 'features/ioc/IOCContainerBuilder';

export interface CreateSceneClass {
  createScene: (engine: Engine, container: Container) => Promise<Scene>;
  createContainer?: (container: Container) => Promise<IOCContainerBuilder>;
  preTasks?: Promise<unknown>[];
}

export interface CreateSceneModule {
  default: CreateSceneClass;
}

export const getSceneModuleWithName = (name: string): Promise<CreateSceneClass> => {
  return import('assets/scenes/' + name).then((module: CreateSceneModule) => {
    return module.default;
  });
};
