import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';

import CourseDropdown from 'features/course/CourseDropdown';
import LocaleDropdown from 'features/locale/LocaleDropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      position: 'fixed',
      top: '10%',
      left: '5%',
      marginRight: '-140px',
      width: '280px',
    },
    menuContent: {
      background: 'transparent',
      flexGrow: 1,
      marginLeft: 6,
      marginTop: 28,
      padding: 10,
    },
    menuDivider: {
      background: theme.palette.primary.main,
      height: 35,
      width: 2,
      marginLeft: 15,
      marginTop: 10,
    },
  })
);

const TerrainSelection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center" height="auto">
      <Box
        className={classes.menuContent}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        width="auto"
      >
        <LocaleDropdown />
        <Divider className={classes.menuDivider} orientation="vertical" flexItem variant="fullWidth" />
        <CourseDropdown />
      </Box>
    </Box>
  );
};

export default TerrainSelection;
