import { Fragment, MouseEvent, useRef, useState } from 'react';
import { Avatar, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useInjection, useObservable } from '../../app/hooks';
import LocalesService from 'services/locale.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuButton: {
      border: `0.1px solid`,
      height: '45px',
      width: '45px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuText: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      marginLeft: 15,
      justifyContent: 'flex-start',
      '&:active': {
        outline: 'none',
      },
    },
    menuItem: {
      ...theme.typography.tab,
      backgroundColor: 'transparent',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuIcon: {
      outline: '1px solid black',
      borderRadius: '5px',
      height: '45px',
      width: '80px',
    },
    menuImage: {
      marginRight: '5px',
      borderRadius: '5px',
      height: '100%',
      width: '100%',
    },
    listItemIcon: {
      height: '45px',
      width: '80px',
    },
  })
);

const LocaleDropdown = () => {
  const classes = useStyles();

  const localeService = useInjection(LocalesService);
  const current = useObservable(localeService.currentLocale$);
  const locales = useObservable(localeService.locales$);

  const anchorEl = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => {
    setOpenMenu(true);
  };

  const handleCloseMenu = (e: MouseEvent<HTMLElement>) => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (e: MouseEvent<HTMLElement>, i: number) => {
    setOpenMenu(false);
    localeService.setLocaleByCode(locales[i].localeCode);
  };

  return (
    <Fragment>
      <Avatar
        id="locale-select-button"
        className={classes.menuButton}
        alt=""
        src={current && current.iconUri ? current.iconUri : ''}
        aria-owns={anchorEl ? 'locale-select-menu' : undefined}
        aria-haspopup={anchorEl ? 'true' : undefined}
        ref={anchorEl}
        style={{ borderColor: current && current.iconColor ? current.iconColor : 'black' }}
        onClick={handleOpenMenu}
      ></Avatar>
      <Typography className={classes.menuText} onClick={handleOpenMenu} variant="h3">
        {current ? current.localeCode : ''}
      </Typography>

      <Menu
        id="locale-select-menu"
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{ onMouseLeave: handleCloseMenu }}
      >
        {locales.map((option, i) =>
          current?.localeId !== option.localeId ? (
            <MenuItem
              key={`menuItemOption${i}`}
              classes={{ root: classes.menuItem }}
              onClick={(e: MouseEvent<HTMLElement>) => {
                handleMenuItemClick(e, i);
                handleCloseMenu(e);
              }}
              disableGutters
              disableRipple
            >
              <Avatar
                className={classes.menuButton}
                alt=""
                src={option.iconUri ? option.iconUri : ''}
                style={{ borderColor: option.iconColor ? option.iconColor : 'black' }}
              ></Avatar>
              <Typography className={classes.menuText} variant="h3">
                {option.localeCode}
              </Typography>
            </MenuItem>
          ) : null
        )}
      </Menu>
    </Fragment>
  );
};

export default LocaleDropdown;
