import { useEffect } from 'react';

import { useInjection } from 'app/hooks';
import LocalesService from 'services/locale.service';

const LocaleContainer = () => {
  const localeService = useInjection(LocalesService);
  const defaultLocaleCode = 'ARE';

  useEffect(() => {
    localeService.fetchAll(defaultLocaleCode);
  }, []);

  return null;
};

export default LocaleContainer;
