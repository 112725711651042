import 'reflect-metadata';

import { inject, injectable } from 'inversify';
import StateProducer from 'features/state';
import { Nullable } from '@babylonjs/core';
import { getCourses, getLocales } from 'api/axios';
import { Course } from 'interfaces/course.interface';
import { Logger } from './logger.service';

@injectable()
export default class CourseService {
  @inject(Logger) public logger!: Logger;

  private _courses$ = new StateProducer([] as Course[]);

  public get courses$() {
    return this._courses$.consumer;
  }
  private _currentCourse$ = new StateProducer(null as Nullable<Course>);
  public get currentCourse$() {
    return this._currentCourse$.consumer;
  }

  constructor() {}

  public async fetchAll(localeId: string) {
    this.logger.info(`Fetch courses from server\n where localeId="${localeId}"`);

    const courses = await getCourses(localeId);

    if (courses.length === 0) {
      this.logger.warn(`No courses were found\n where localeId="${localeId}".`);
    }

    this._setCourses(courses);
  }

  protected _setCourse(course: Course | null) {
    this.logger.trace('Set current course', course);

    if ((this._currentCourse$.value = course)) {
      return;
    }
    this._currentCourse$.value = course;
  }

  protected _setCourses(courses: Course[]) {
    this.logger.trace('Set courses', courses);
    if (
      !this._currentCourse$.value ||
      courses.find(l => l.courseId === this._currentCourse$.value?.courseId) === undefined
    ) {
      const current = courses[0] || null;
      this._setCourse(current);
    }
    this._courses$.value = courses;
  }

  public setCourseByCode(code: string) {
    this.logger.info('Set course by code', code);
    this._setCourseBySelector(course => course.courseCode === code);
  }

  public setCourseById(courseId: string) {
    this.logger.info('Set course by ID', courseId);

    this._setCourseBySelector(course => course.courseId === courseId);
  }

  private _setCourseBySelector(selector: (course: Course) => boolean) {
    const courses = this._courses$.value;
    if (!courses) {
      this.logger.warn('Current course set before courses were fetched');
      return;
    }

    const current = courses.find(selector) ?? null;

    if (!current) {
      this.logger.warn('No such couse was found, current course will be unset');
    }

    this._setCourse(current);
  }
}
