import { Locale } from 'interfaces/locale.interface';
import { get } from './request.axios';

const apiPath = `/locale`;

export const getLocale = async (localeId: string) => {
  return get<{ data: Locale }>(`${apiPath}/${localeId}`).then(response => {
    return response.data;
  });
};

export const getLocales = async () => {
  return get<{ data: Locale[] }>(`${apiPath}`).then(response => {
    return response.data;
  });
};
