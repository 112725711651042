import 'reflect-metadata';
import { inject, injectable } from 'inversify';

import { Nullable } from '@babylonjs/core';

import { getAthletes } from 'api/axios';
import { Athlete } from 'interfaces/athlete.interface';
import { Logger } from './logger.service';
import StateProducer from 'features/state';

@injectable()
export default class AthleteService {
  @inject(Logger) public logger!: Logger;

  private _athletes$ = new StateProducer([] as Athlete[]);
  public get athletes$() {
    return this._athletes$.consumer;
  }
  private _currentAthlete$ = new StateProducer(null as Nullable<Athlete>);
  public get currentAthlete$() {
    return this._currentAthlete$.consumer;
  }

  constructor() {}

  public async fetchAll(defaultCode?: number) {
    this.logger.info(`Fetch athletes from server.`, defaultCode ? `Default code="${defaultCode}"` : '');

    const athletes = await getAthletes();

    if (athletes.length === 0) {
      this.logger.error(
        `Athletes couldn't be fetched, it probably indicates a problem with either the server or your connection.`
      );
    }

    this._setAthletes(athletes, defaultCode);
  }

  protected _setAthlete(athlete: Athlete | null) {
    this.logger.trace('Set current athlete', athlete);

    if ((this._currentAthlete$.value = athlete)) {
      return;
    }
    this._currentAthlete$.value = athlete;
  }

  protected _setAthletes(athletes: Athlete[], defaultCode?: number) {
    this.logger.trace('Set athletes', athletes);

    if (
      !this._currentAthlete$.value ||
      athletes.find(l => l.athleteId === this._currentAthlete$.value?.athleteId) === undefined
    ) {
      const current = defaultCode ? athletes.find(athlete => athlete.fisCode === defaultCode) ?? null : null;
      if (!current && defaultCode) {
        this.logger.warn(
          `No athlete with the default code was found despite the default code "${defaultCode}" being specified, current athlete will be unset`
        );
      }

      this._setAthlete(current);
    }
    this._athletes$.value = athletes;
  }

  public setAthleteByCode(code: number) {
    this.logger.info('Set athlete by code', code);

    this._setAthleteBySelector(athlete => athlete.fisCode === code);
  }

  public setAthleteById(athleteId: string) {
    this.logger.info('Set athlete by ID', athleteId);

    this._setAthleteBySelector(athlete => athlete.athleteId === athleteId);
  }

  private _setAthleteBySelector(selector: (athlete: Athlete) => boolean) {
    const athletes = this._athletes$.value;
    if (!athletes) {
      this.logger.warn('Current athlete set before athletes were fetched');
      return;
    }

    const current = athletes.find(selector) ?? null;
    if (!current) {
      this.logger.warn('No such athlete was found, current athlete will be unset');
    }

    this._setAthlete(current);
  }
}
