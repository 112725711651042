import { Fragment } from 'react';
import { Avatar, Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useInjection, useObservable } from 'app/hooks';
import AthleteService from 'services/athlete.service';
import BiometricService from 'services/biometric.service';

const _formatNumber = (n: number, len = 0): string => {
  return Number(n).toFixed(len);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      backgroundColor: '#303030',
    },
    playerCard: {
      backgroundColor: 'transparent',
      marginLeft: 20,
      paddingBottom: '25px',
      paddingTop: '75px',
    },
    playerContent: {
      flexGrow: 1,
    },
    playerDetail: {
      paddingTop: '25px',
    },
    playerDetailRow: {
      alignItems: 'bottom',
      flexDirection: 'row',
      justifyContent: 'left',
    },
    playerDetailSubtext: {
      fontWeight: 200,
      opacity: 0.6,
      marginTop: 'auto',
      paddingLeft: '5px',
    },
    playerDetailTitle: {
      fontWeight: 200,
      opacity: 0.6,
      marginTop: 'auto',
    },
    playerDetailInfo: {
      fontWeight: 400,
      paddingBottom: 5,
      paddingLeft: 20,
    },
    playerDivider: {
      background: theme.palette.primary.main,
      height: 3,
      marginTop: 10,
      opacity: 0.6,
    },
    playerIcon: {
      marginLeft: '45px',
      border: `0.1px solid`,
      borderColor: theme.palette.secondary.main,
    },
    playerText: {},
  })
);

const BiometricsMenu = () => {
  const classes = useStyles();

  const biometricService = useInjection(BiometricService);
  const biometrics = useObservable(biometricService.biometrics$);
  const athleteService = useInjection(AthleteService);
  const athlete = useObservable(athleteService.currentAthlete$);

  const handleClickIcon = () => {};

  return (
    <Fragment>
      <Box className={classes.menuContainer} display="flex" flexDirection="column" justifyContent="center">
        <Card className={classes.playerCard} elevation={0}>
          <CardContent className={classes.playerContent}>
            <Typography variant="h5" style={{ opacity: 0.6 }}>
              {athlete?.forename.toUpperCase()}
            </Typography>
            <Box alignItems="center" display="flex" flexDirection="row" justifyContent="left" width="auto">
              <Typography variant="h3" style={{ fontWeight: 500 }}>
                {athlete?.surname.toUpperCase()}
              </Typography>
              <Avatar
                className={classes.playerIcon}
                onClick={handleClickIcon}
                alt=""
                src="https://storage.googleapis.com/tntx-stsa-storage-cdn/static/locale/sweden-200.png"
              />
              <Typography variant="h5" style={{ fontWeight: 500, marginLeft: 25 }}>
                SWE
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                style={{ height: 35, width: 2, marginLeft: 15, marginTop: 10 }}
                variant="fullWidth"
              />
              <Typography variant="h5" style={{ fontWeight: 500, marginLeft: 15 }}>
                GS
              </Typography>
            </Box>
            <Divider className={classes.playerDivider} style={{ width: 200 }} />
            <Box alignItems="left" display="flex" flexDirection="row">
              <Box
                className={classes.playerDetail}
                alignItems="left"
                display="flex"
                flexDirection="column"
                width="auto"
              >
                <Typography className={classes.playerDetailTitle} variant="subtitle2">
                  Height
                </Typography>
                <Box className={classes.playerDetailRow} display="flex">
                  <Typography variant="h4" style={{ fontWeight: 400 }}>
                    {_formatNumber(
                      biometrics.filter(o => o.biometricType?.biometricTypeDescription === 'Height')[0]?.biometricValue
                    )}
                  </Typography>
                  <Typography className={classes.playerDetailSubtext} variant="subtitle2">
                    cm
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.playerDetail}
                alignItems="left"
                display="flex"
                flexDirection="column"
                width="auto"
                style={{ paddingLeft: 65 }}
              >
                <Typography className={classes.playerDetailTitle} variant="subtitle2">
                  Weight
                </Typography>
                <Box className={classes.playerDetailRow} display="flex">
                  <Typography variant="h4" style={{ fontWeight: 400 }}>
                    {_formatNumber(
                      biometrics.filter(o => o.biometricType?.biometricTypeDescription === 'Weight')[0]?.biometricValue,
                      1
                    )}
                  </Typography>
                  <Typography className={classes.playerDetailSubtext} variant="subtitle2">
                    kg
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider className={classes.playerDivider} style={{ width: 250 }} />
            <Box alignItems="left" display="flex" flexDirection="row">
              <Box
                className={classes.playerDetail}
                alignItems="left"
                display="flex"
                flexDirection="column"
                width="auto"
              >
                <Typography className={classes.playerDetailTitle} variant="subtitle2" style={{ paddingBottom: 5 }}>
                  Born:
                </Typography>
                <Typography className={classes.playerDetailTitle} variant="subtitle2" style={{ paddingBottom: 5 }}>
                  From:
                </Typography>
                <Typography className={classes.playerDetailTitle} variant="subtitle2" style={{ paddingBottom: 5 }}>
                  FIS Code:
                </Typography>
                <Typography className={classes.playerDetailTitle} variant="subtitle2" style={{ paddingBottom: 5 }}>
                  FIS Debut:
                </Typography>
              </Box>
              <Box
                className={classes.playerDetail}
                alignItems="left"
                display="flex"
                flexDirection="column"
                width="auto"
                style={{ paddingLeft: 65 }}
              >
                <Typography className={classes.playerDetailInfo} variant="subtitle2">
                  {athlete?.dateOfBirth.toString()}
                </Typography>
                <Typography className={classes.playerDetailInfo} variant="subtitle2">
                  {athlete?.homeTown}
                </Typography>
                <Typography className={classes.playerDetailInfo} variant="subtitle2">
                  {athlete?.fisCode.toString()}
                </Typography>
                <Typography className={classes.playerDetailInfo} variant="subtitle2">
                  {athlete?.fisDebut.toString()}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default BiometricsMenu;
