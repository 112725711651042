import 'reflect-metadata';
import { Container, inject, injectable, preDestroy } from 'inversify';

import { Color3, Color4, Engine, Nullable, Scene } from '@babylonjs/core';

import StateProducer from 'features/state';
import { IOCContainerBuilder } from 'features/ioc/IOCContainerBuilder';
import { getSceneModuleWithName } from 'api/babylon/scripts/scene';

import { Logger } from './logger.service';

@injectable()
export default class SceneService {
  @inject(Logger) public logger!: Logger;

  private _scene$ = new StateProducer(null as Nullable<Scene>);
  public get scene$() {
    return this._scene$.consumer;
  }

  private _engine?: Engine;
  private _canvas?: HTMLCanvasElement;

  private _sceneContainer: Container;

  constructor(@inject(Container) container: Container) {
    this._sceneContainer = container.createChild({ defaultScope: 'Singleton' });
  }

  public async loadSceneModule(moduleName: string): Promise<Scene> {
    this.logger.info('Load scene module:', moduleName);

    const bgColorRgb = this._canvas?.style.backgroundColor.match(/\d+/g)?.map(n => {
      return parseInt(n) / 255;
    });

    this.logger.trace('Load ts module', module);
    const sceneSubModule = await getSceneModuleWithName(moduleName);

    this.logger.trace('Start pre tasks');
    await Promise.all(sceneSubModule.preTasks || []);

    this.logger.trace('Build container');
    await sceneSubModule.createContainer?.(this._sceneContainer).then(builder => {
      builder.build();
    });

    this.logger.trace('Create scene');
    const scene = await sceneSubModule.createScene(this._engine!, this._sceneContainer);
    scene.clearColor = Color4.FromColor3(Color3.FromArray(bgColorRgb!), 1.0);
    scene.useRightHandedSystem = true;

    return scene;
  }

  public setCanvas(canvas: HTMLCanvasElement) {
    this.logger.info('Set canvas', canvas);

    this.unsetScene();

    this._canvas = canvas;
    this._engine = new Engine(this._canvas);

    // does not get called on resize
    // this._canvas.addEventListener('resize', this._resizeEngine);
  }

  public resizeEngine = () => {
    this._engine?.resize();
  };

  public runRenderLoop() {
    this.logger.info('Run render loop');

    if (!this._checkResources()) {
      this.logger.warn('setScene must be called before runRenderLoop');
      return;
    }
    this._engine?.runRenderLoop(() => {
      this._scene$.value?.render();
    });
  }

  public stopRenderLoop() {
    this.logger.info('Stop render loop');
    this._engine?.stopRenderLoop();
  }

  public async setScene(name: string) {
    this.logger.info('Set scene', name);

    if (!this._checkResources()) {
      this.logger.warn('setCanvas must be called before setScene');
      return;
    }

    this._scene$.value = await this.loadSceneModule(name);
  }

  public unsetScene() {
    this.logger.info('Unset scene');

    this._sceneContainer.unbindAll();

    const scene = this._scene$.value;
    scene?.dispose();
    this._scene$.value = null;
  }

  @preDestroy()
  public dispose() {
    this.unsetScene();

    this.scene$.clear();
    this._engine?.dispose();
  }

  private _checkResources = (): boolean => {
    return this._canvas !== undefined && this._engine !== undefined;
  };
}
