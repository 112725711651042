import 'reflect-metadata';
import { clone, cloneDeep } from 'lodash';
import { inject, injectable } from 'inversify';

import { Nullable } from '@babylonjs/core';

import StateProducer from 'features/state';
import { Logger } from './logger.service';

@injectable()
export default class CameraService {
  @inject(Logger) public logger!: Logger;

  private _currentCamera$ = new StateProducer(null as Nullable<{ id: string }>);
  public get currentCamera$() {
    return this._currentCamera$.consumer;
  }
  private _availableCameras$ = new StateProducer(new AvailableCameras());
  public get availableCameras$() {
    return this._availableCameras$.consumer;
  }

  constructor() {}

  public setAvailableCameras(cameras: Array<{ id: string; name: string }>, currentId?: string) {
    this._availableCameras$.value = new AvailableCameras(cameras);
    const current = currentId ? this._availableCameras$.value.byId(currentId) : cameras[0];

    this._currentCamera$.value = current ? { id: current.id } : null;
  }

  public setCurrent(id: string) {
    this.logger.info('Select current camera with id', id);
    return (this._currentCamera$.value = this._availableCameras$.value.byId(id) ?? this._currentCamera$.value);
  }

  public clearAvailableCameras() {
    this._availableCameras$.value = new AvailableCameras();
    this._currentCamera$.value = null;
  }
}

class AvailableCameras {
  constructor(public cameras: Array<{ id: string; name: string }> = []) {}

  public byName(name: string) {
    return this.cameras.filter(camera => camera.name === name);
  }

  public byId(id: string) {
    return this.cameras.find(camera => camera.id === id) ?? null;
  }
}
