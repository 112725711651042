import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import HudRaceStats from './HudRaceStats';
import HudPlayback from './HudPlayback';
import HudCameraSelector from './HudCameraSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: 'transparent',
      margin: 0,
      maxHeight: '100%',
    },
  })
);

export interface IHudControl {
  setHudEnabled: Dispatch<SetStateAction<boolean>>;
}

const HudControls = (props: PropsWithChildren<IHudControl>) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <HudRaceStats setHudEnabled={props.setHudEnabled} />
      <HudPlayback />
      <HudCameraSelector />
    </Paper>
  );
};

export default HudControls;
