import { useEffect } from 'react';

import SkiGatesService from 'services/skiGates.service';
import TerrainsService from 'services/terrain.service';
import { useInjection, useObservable } from 'app/hooks';

const GatesContainer = () => {
  const gatesService = useInjection(SkiGatesService);
  const terrainService = useInjection(TerrainsService);

  const gates = useObservable(gatesService.gates$);
  const terrain = useObservable(terrainService.currentTerrain$);

  // valid gate entries
  useEffect(() => {}, [gates]);

  useEffect(() => {
    if (terrain?.terrainId) {
      gatesService.fetch(terrain.terrainId);
    }
  }, [terrain]);

  return null;
};

export default GatesContainer;
