import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Theme,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import { registerUser } from './authenticationSlice';
import { validateEmail, validateName, validatePassword } from './validation';

import { useAppDispatch } from 'app/hooks';

import logo from 'assets/images/tntxlogo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '25px',
      marginBottom: '25px',
    },
    dialog: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    iconButton: {},
    logo: {
      height: '7em',
    },
    loginButton: {
      marginTop: '10px',
      marginBottom: '15px',
    },
    registerGrid: {
      marginTop: '5px',
      marginBottom: '25px',
    },
  })
);

interface ITextField {
  formName: string;
  formEmail: string;
  formPassword: string;
}

interface RegisterProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const Register = ({ openDialog, setOpenDialog }: RegisterProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [errorTextEmail, setErrorTextEmail] = useState('');
  const [errorTextName, setErrorTextName] = useState('');
  const [errorTextPassword, setErrorTextPassword] = useState('');

  const [inputValues, setInputValues] = useState<ITextField>({
    formName: '',
    formEmail: '',
    formPassword: '',
  });

  const handleRegisterUser = async () => {
    if (
      !(
        validateName(inputValues?.formName) ||
        validateEmail(inputValues?.formEmail) ||
        validatePassword(inputValues?.formPassword)
      )
    ) {
      return;
    }
    await dispatch(
      registerUser({
        email: `${inputValues.formEmail}`,
        userName: `${inputValues.formName}`,
        userPassword: `${inputValues.formPassword}`,
      })
    );
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInputValues({ formName: '', formEmail: '', formPassword: '' });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;
    setInputValues(prevState => ({ ...prevState, [id]: value }));
  };

  const handleValidateEmail = () => {
    if (validateEmail(inputValues?.formEmail)) {
      setErrorTextEmail('');
      return;
    }
    setErrorTextEmail('An email address belonging to domain @tntx.se or @skidor.com is required for registration');
  };

  const handleValidateName = () => {
    if (validateName(inputValues?.formName)) {
      setErrorTextName('');
      return;
    }
    setErrorTextName('A valid first and last name [eg: Dave Lombardo] is required for registration');
  };

  const handleValidatePassword = () => {
    if (validatePassword(inputValues?.formPassword)) {
      setErrorTextPassword('');
      return;
    }
    setErrorTextPassword(
      'A password of 6-16 characters, with at least one number and special character, is required for registration'
    );
  };

  return (
    <Fragment>
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openDialog}
        onClose={handleCloseDialog}
        className={classes.dialog}
        maxWidth="sm"
      >
        <DialogContent>
          <img alt="company logo" src={logo} className={classes.logo} />
          <DialogContentText>STSA Client Authentication</DialogContentText>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <TextField
            onBlur={handleValidateName}
            onChange={handleInputChange}
            error={errorTextName !== ''}
            helperText={errorTextName}
            margin="dense"
            id="formName"
            label="Name"
            type="Name"
            value={inputValues?.formName}
            fullWidth={true}
            variant="outlined"
          />
          <TextField
            onBlur={handleValidateEmail}
            onChange={handleInputChange}
            error={errorTextEmail !== ''}
            helperText={errorTextEmail}
            margin="dense"
            id="formEmail"
            label="Email"
            type="email"
            value={inputValues?.formEmail}
            fullWidth={true}
            variant="outlined"
          />
          <TextField
            onBlur={handleValidatePassword}
            onChange={handleInputChange}
            error={errorTextPassword !== ''}
            helperText={errorTextPassword}
            margin="dense"
            id="formPassword"
            label="Password"
            type="password"
            value={inputValues?.formPassword}
            fullWidth={true}
            variant="outlined"
          />
          <Button
            className={classes.loginButton}
            onClick={handleRegisterUser}
            color="primary"
            fullWidth={true}
            variant="contained"
          >
            Register
          </Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Register;
