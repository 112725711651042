import { Athlete } from 'interfaces/athlete.interface';
import { get } from './request.axios';

const apiPath = `/athlete`;

export const getAthlete = async (athleteId: string) => {
  return get<{ data: Athlete }>(`${apiPath}/${athleteId}`).then(response => {
    return response.data;
  });
};

export const getAthletes = async () => {
  return get<{ data: Athlete[] }>(`${apiPath}`).then(response => {
    return response.data;
  });
};
