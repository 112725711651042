import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Athlete } from 'interfaces/athlete.interface';
import { RootState } from 'app/store';

export interface AthleteState {
  athlete: Athlete | null;
  athletes: Athlete[];
}

const initialState: AthleteState = {
  athlete: null,
  athletes: [],
};

export const athleteSlice = createSlice({
  name: 'athlete',
  initialState,
  reducers: {
    setAthlete: (state, action: PayloadAction<Athlete>) => {
      state.athlete = action.payload;
    },
    setAthletes: (state, action: PayloadAction<Athlete[]>) => {
      state.athletes = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setAthlete, setAthletes } = athleteSlice.actions;

export const selectAthlete = (state: RootState) => state.athlete.athlete;
export const selectAthletes = (state: RootState) => state.athlete.athletes;

export default athleteSlice.reducer;
