import { Fragment, PropsWithChildren, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import SceneService from 'services/scene.service';
import { useInjection } from 'app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    babylonContainer: {
      margin: 0,
      padding: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    renderCanvas: {
      position: 'fixed',
      outline: 'none',
    },
  })
);

interface GameCanvasProps {
  sceneName: string;
  height?: string;
  width?: string;
  margin?: string;
  backgroundColor?: string;
}

export const GameCanvas = (props: PropsWithChildren<GameCanvasProps>) => {
  const classes = useStyles();
  const canvas = useRef<HTMLCanvasElement>(null);

  const sceneService = useInjection(SceneService);

  useEffect(() => {
    sceneService.setCanvas(canvas.current!);
    sceneService.setScene(props.sceneName);

    sceneService.runRenderLoop();

    return () => {
      sceneService.unsetScene();
      sceneService.stopRenderLoop();
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    // disable context menu (caused by hammerjs)
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('resize', sceneService.resizeEngine);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('resize', sceneService.resizeEngine);
    };
  });

  return (
    <Fragment>
      <div className={classes.babylonContainer}>
        <canvas
          className={classes.renderCanvas}
          ref={canvas}
          style={{
            height: props.height ? props.height : '100%',
            width: props.width ? props.width : '100%',
            marginLeft: props.margin ? props.margin : 'auto',
            backgroundColor: props.backgroundColor ? props.backgroundColor : '#303030',
          }}
        ></canvas>
        {props.children}
      </div>
    </Fragment>
  );
};
