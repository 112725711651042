import { Container } from 'inversify';
import { createContext, PropsWithChildren } from 'react';

export const IOCContext = createContext<{ container: Container | null }>({ container: null });

export interface IOCProviderProps {
  container: Container;
}

const IOCProvider = (props: PropsWithChildren<IOCProviderProps>) => {
  return <IOCContext.Provider value={{ container: props.container }}>{props.children}</IOCContext.Provider>;
};

export default IOCProvider;
