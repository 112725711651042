import { PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import { selectStatus } from './authenticationSlice';

type ProtectedRouteProps = {
  redirectPath: string;
} & RouteProps;

const ProtectedRoute = (props: PropsWithChildren<ProtectedRouteProps>) => {
  const { redirectPath, ...routeProps } = props;
  const isAuthenticated = useAppSelector(selectStatus);

  if (isAuthenticated) {
    return <Route {...routeProps} />;
  }
  return <Redirect to={{ pathname: redirectPath }} />;
};

export default ProtectedRoute;
