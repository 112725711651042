import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import StateProducer from 'features/state';
import { RacePath } from 'interfaces/racePath.interface';
import { Nullable, Vector3 } from '@babylonjs/core';
import SkiGatesService from './skiGates.service';
import SceneService from './scene.service';
import { Logger } from './logger.service';
import { SkiGate } from 'interfaces/skiGate.interface';
import { cloneDeep } from 'lodash';

export interface AccelerationStructures {
  gateCenters: Vector3[];
  gateRadii: number[];
  gateTangents: Vector3[];
  inflectionPoints: Vector3[];
}

@injectable()
export default class RacePathService {
  @inject(Logger) public logger!: Logger;

  private _racePath$ = new StateProducer<Nullable<RacePath>>(null);
  public get racePath$() {
    return this._racePath$.consumer;
  }
  private _recalculate$ = new StateProducer<Nullable<{ updateRacePath(racePath: RacePath): void }>>(null);
  public get recalculate$() {
    return this._recalculate$.consumer;
  }

  private _accelerationStructures$ = new StateProducer<Nullable<AccelerationStructures>>(null);
  public get accelerationStructures$() {
    return this._accelerationStructures$.consumer;
  }

  constructor(@inject(SkiGatesService) private _skiGateService: SkiGatesService) {}

  public recalculate() {
    this.logger.info('Order recalculation of the race path');

    this._recalculate$.value = { updateRacePath: this._updateRacePath };
  }

  public overrideAccelerationStructures(accelerationStructures: AccelerationStructures) {
    this.logger.info(
      'Overide acceleration structures\n'
      // 'Old ones:',
      // this._accelerationStructures$.value,
      // '\nNew ones:',
      // accelerationStructures
    );

    if (accelerationStructures != this._accelerationStructures$.value) {
      this._accelerationStructures$.value = accelerationStructures;
    } else {
      // New and old structures are the same reference
      this._accelerationStructures$.forceNotifyObservers();
    }
  }

  private _updateRacePath = (racePath: RacePath) => {
    this.logger.trace('Racepath recalculated', racePath);

    this._recalculate$.value = null;
    this._racePath$.value = racePath;
  };
}
