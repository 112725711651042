import { useEffect } from 'react';

import { useInjection } from 'app/hooks';
import AthleteService from 'services/athlete.service';

const AthleteContainer = () => {
  const athleteService = useInjection(AthleteService);
  const defaultAthleteCode = 501987;

  useEffect(() => {
    athleteService.fetchAll(defaultAthleteCode);
  }, []);

  return null;
};

export default AthleteContainer;
