import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'athlete',
    'authentication',
    'biometrics',
    'course',
    'darkMode',
    'hud',
    'locale',
    'miniMap',
    'racePath',
    'skiGate',
    'terrain',
  ],
};

export default persistConfig;
