import { Fragment } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { PAGE_TITLE_HOME } from 'utils/constants';

const useStyles = makeStyles(() =>
  createStyles({
    pageRoot: {
      height: '100%',
      width: '100%',
      margin: 'none',
    },
  })
);

const Home = () => {
  const classes = useStyles();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{PAGE_TITLE_HOME}</title>
      </Helmet>
      <Fragment>
        <div className={classes.pageRoot} />
      </Fragment>
    </HelmetProvider>
  );
};

export default Home;
