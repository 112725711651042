import { SkiGate } from 'interfaces/skiGate.interface';
import { get, update } from './request.axios';

const apiPath = `/gates`;
const apiSubPath = `/terrain`;

export const getGate = async (gateId: string) => {
  return get<{ data: SkiGate }>(`${apiPath}/${gateId}`).then(response => {
    return response.data;
  });
};

export const getGates = async (terrainId: string) => {
  return get<{ data: SkiGate[] }>(`${apiSubPath}/${terrainId}/gates`).then(response => {
    return response.data;
  });
};

export const updateGates = async (skiGates: SkiGate[]) => {
  skiGates.forEach(gate => {
    update<SkiGate>(`${apiPath}/${gate.skiGateId}`, gate);
  });
};
