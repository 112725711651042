import 'reflect-metadata';

import { inject, injectable } from 'inversify';
import StateProducer from 'features/state';
import { Locale } from 'interfaces/locale.interface';
import { Nullable } from '@babylonjs/core';
import { getLocales } from 'api/axios';
import SceneService from './scene.service';
import PersistanceService, { PersistanceBuilder, scoped } from './persistance.service';
import LoggerService, { ConsoleLogger, Logger, LogLevel } from './logger.service';

@injectable()
export default class LocaleService {
  // @inject(Logger)
  public logger: Logger = new ConsoleLogger('LocaleService', LogLevel.TRACE);

  protected _locales$ = new StateProducer([] as Locale[]);
  public get locales$() {
    return this._locales$.consumer;
  }
  protected _currentLocale$ = new StateProducer(null as Nullable<Locale>);
  public get currentLocale$() {
    return this._currentLocale$.consumer;
  }

  constructor() {}

  public async fetchAll(defaultCode?: string) {
    this.logger.info(`Fetch locales from server.`, defaultCode ? `Default code="${defaultCode}"` : '');

    const locales = await getLocales();

    if (locales.length === 0) {
      this.logger.error(
        `Locales couldn't be fetched, it probably indicates a problem with either the server or your connection.`
      );
    }

    this._setLocales(locales, defaultCode);
  }

  protected _setLocale(locale: Locale | null) {
    this.logger.trace('Set current locale', locale);

    if ((this._currentLocale$.value = locale)) {
      return;
    }
    this._currentLocale$.value = locale;
  }

  protected _setLocales(locales: Locale[], defaultCode?: string) {
    this.logger.trace('Set locales', locales);

    if (
      !this._currentLocale$.value ||
      locales.find(l => l.localeId === this._currentLocale$.value?.localeId) === undefined
    ) {
      const current = defaultCode ? locales.find(locale => locale.localeCode === defaultCode) ?? null : null;

      if (!current && defaultCode) {
        this.logger.warn(
          `No locale with the default code was found despite the default code "${defaultCode}" being specified, current locale will be unset`
        );
      }

      this._setLocale(current);
    }
    this._locales$.value = locales;
  }

  public setLocaleByCode(code: string) {
    this.logger.info('Set locale by code', code);
    this._setLocaleBySelector(locale => locale.localeCode === code);
  }

  public setLocaleById(localeId: string) {
    this.logger.info('Set locale by ID', localeId);
    this._setLocaleBySelector(locale => locale.localeCode === localeId);
  }

  private _setLocaleBySelector(selector: (locale: Locale) => boolean) {
    const locales = this._locales$.value;
    if (!locales) {
      this.logger.warn('Current locale set before locales were fetched');
      return;
    }

    const current = locales.find(selector) ?? null;

    if (!current) {
      this.logger.warn('No such locale was found, current locale will be unset');
    }

    this._setLocale(current);
  }
}

@injectable()
export class LocaleServiceWithPersistance extends LocaleService {
  @inject(PersistanceBuilder) @scoped('LocaleService') private _persistance!: PersistanceBuilder;

  // @inject(Logger) public logger!: ConsoleLogger;

  constructor() {
    super();
    window.onbeforeunload = () => {
      this._persistance.store('locales', this._locales$.value);
      this._persistance.store('currentCode', this._currentLocale$.value?.localeCode ?? null);
    };
  }

  public async fetchAll(defaultCode?: string | undefined): Promise<void> {
    this.logger.trace('Retriving from persist store');

    const locales: Nullable<Locale[]> = null;
    await this._persistance.retrive('locales');
    const currentLocaleCode: Nullable<string> = null;
    await this._persistance.retrive('currentCode');

    if (locales && locales.length > 0) {
      this.logger.trace('Retrived: ', locales, currentLocaleCode);

      super._setLocales(locales, currentLocaleCode ?? defaultCode);
    } else {
      this.logger.trace('Failed Retriving');
      super.fetchAll(currentLocaleCode ?? defaultCode);
    }
  }
}
