import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface MeshLabelState {
  label: string;
  position: ScreenCoordinate;
  toggle: boolean;
}

const initialState: MeshLabelState = {
  label: '',
  position: { x: 0, y: 0 },
  toggle: false,
};

export const meshLabelSlice = createSlice({
  name: 'ui/meshLabel',
  initialState,
  reducers: {
    setMeshLabel: (state, action: PayloadAction<string>) => {
      state.label = action.payload;
    },
    setMeshLabelPosition: (state, action: PayloadAction<ScreenCoordinate>) => {
      state.position = action.payload;
    },
    toggleMeshLabel: state => {
      state.toggle = !state.toggle;
    },
  },
  extraReducers: {},
});

export const { setMeshLabel, setMeshLabelPosition, toggleMeshLabel } = meshLabelSlice.actions;

export const selectLabel = (state: RootState) => state.meshLabel.label;
export const selectLabelPosition = (state: RootState) => state.meshLabel.position;
export const selectLabelToggle = (state: RootState) => state.meshLabel.toggle;

export default meshLabelSlice.reducer;
